/* eslint-disable react-hooks/exhaustive-deps */
import { createRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { Button } from './';
import {
  colors,
  fonts,
  messageColors,
  maxDevice,
  toastColors,
} from '../styles/variables';
import PropTypes from 'prop-types';
import {
  ButtonsRowContainer,
  ButtonContainer,
} from '../styles/library/modalStyles';
import { check, download, errorCircleRed, info, times } from '../assets';
import { ButtonThemes } from '../styles/themes';
import { GenerateFileIcon } from '../utils';

const DropZone = ({
  text = 'Drag and Drop file here to upload',
  buttonText = 'Or Click to Select',
  subtext = 'Max File Size: 2MB JPG or PNG',
  maxMB = 2,
  type = 'image',
  accepted = { 'image/png': ['.png'], 'image/jpeg': ['.jpeg'] },
  multiple = false,
  dispatchFunction,
  clearFiles = false,
  setFileData,
  uploadedFileId,
  failedFileId,
  failedFileMessage,
  hide,
  additionalText,
}) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: accepted,
    multiple: multiple,
    maxSize: 1048576 * maxMB,
    onDrop: (acceptedFiles) => {
      // Filter out files with 'complete' or 'error' status
      const filteredFiles = files.filter(
        (file) =>
          !fileStatus.find(
            (f) =>
              f.uuid === file.uuid && ['complete', 'error'].includes(f.status)
          )
      );

      // Add new files
      const jointFiles = [...acceptedFiles, ...filteredFiles];
      setShowMaxFiles(jointFiles.length > 5);
      const clippedFiles = jointFiles.slice(0, 5);

      setFiles(
        clippedFiles.map((file) =>
          Object.assign(file, {
            uuid: file?.uuid || uuidv4(),
            preview: URL.createObjectURL(file),
          })
        )
      );
      setUploadButtonText('Upload Files');
      setDisableUpload(false);
    },
  });
  const dispatch = useDispatch();
  const dropzoneRef = createRef();
  const [files, setFiles] = useState([]);
  const [showMaxFiles, setShowMaxFiles] = useState(false);
  const [convertedFile, setConvertedFile] = useState();
  const [fileStatus, setFileStatus] = useState([]);
  const [processedFiles, setProcessedFiles] = useState(0); // Track completed files
  const [uploadButtonText, setUploadButtonText] = useState('Upload Files');
  const [disableUpload, setDisableUpload] = useState(false);

  function encodeFileAsURL() {
    var fileToLoad = acceptedFiles[0];
    if (fileToLoad) {
      var fileReader = new FileReader();
      fileReader.onload = function (e) {
        var srcData = e.target.result;
        setConvertedFile(srcData);
      };
      if (type === 'csv') {
        fileReader.readAsBinaryString(fileToLoad);
      } else {
        fileReader.readAsDataURL(fileToLoad);
      }
    }
  }

  useEffect(() => {
    if (files && files.length >= 1) {
      encodeFileAsURL();
    }
  }, [files]);

  useEffect(() => {
    if (clearFiles) {
      setFiles([]);
    }
  }, [clearFiles]);

  useEffect(() => {
    if (uploadedFileId) {
      setFileStatus(
        fileStatus.map((f) => {
          if (f.uuid === uploadedFileId) {
            f.status = 'complete';
          }
          return f;
        })
      );
      // Increment the processed files count safely
      setProcessedFiles((prev) => prev + 1);
    }
  }, [uploadedFileId]);

  useEffect(() => {
    if (failedFileId) {
      setFileStatus(
        fileStatus.map((f) => {
          if (f.uuid === failedFileId) {
            f.status = 'error';
            f.message = failedFileMessage || null;
          }
          return f;
        })
      );
      // Increment the processed files count safely
      setProcessedFiles((prev) => prev + 1);
    }
  }, [failedFileId]);

  useEffect(() => {
    if (convertedFile) {
      if (dispatchFunction) dispatch(dispatchFunction(convertedFile));
    }
  }, [convertedFile]);

  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const fileDisplay = () => {
    if (clearFiles) {
      return '';
    }
    if (acceptedFiles && acceptedFiles.length >= 1) {
      const acceptedFilesItems = acceptedFiles.map((file, index) => {
        let fileSize = (file.size / 1000000).toFixed(2);
        return (
          <AcceptedFile key={index}>
            &#10004; {file.path} - {fileSize} MB
          </AcceptedFile>
        );
      });
      return acceptedFilesItems;
    } else if (fileRejections && fileRejections.length >= 1) {
      let file = fileRejections[0];
      let error = file.errors[0];
      let errorMsg;
      switch (error?.code) {
        case 'file-invalid-type':
          errorMsg = 'Unsupported file type';
          // if (type === 'csv') {
          //   errorMsg = 'File must be CSV';
          // } else {
          //   errorMsg = 'File must be JPG or PNG';
          // }
          break;
        case 'file-too-large':
          errorMsg = 'File is larger than 2MB';
          break;
        case 'too-many-files':
          errorMsg = 'Only Upload One File';
          break;
        default:
          errorMsg = 'Invalid file';
      }
      return <RejectedFile>&#10008; Error: {errorMsg}</RejectedFile>;
    } else {
      return <span>Error</span>;
    }
  };

  const removeFile = (file) => {
    const updatedFiles = files.filter((f) => {
      return f !== file;
    });
    setFiles(updatedFiles);
  };

  const generateFileSize = (size, limit = 3) => {
    size /= 1000000;
    return size.toFixed(limit);
  };

  const generateImage = (file) => {
    if (file.type.includes('image')) {
      return file.preview;
    } else {
      return GenerateFileIcon(file, 'type');
    }
  };

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  async function processFile(file) {
    try {
      let base64 = await readFileAsync(file);
      setFileData({ content: base64, uuid: file?.uuid, name: file?.name });

      // Update file status
      setFileStatus((prevStatus) => [
        ...prevStatus,
        { uuid: file.uuid, status: 'loading' },
      ]);
    } catch (err) {
      setFileStatus((prevStatus) => [
        ...prevStatus,
        { uuid: file.uuid, status: 'error', message: 'Failed to process' },
      ]);
    }
  }

  async function processFilesSequentially(files) {
    setUploadButtonText('Processing...')
    for (const file of files) {
      await processFile(file);
    }
  }

  const uploadAllFiles = () => {
    if (disableUpload || files.length === 0) {
        return
    }
    setProcessedFiles(0); // Reset counter
    const uploadingFiles = files.filter(
      (file) => !['complete', 'error'].includes(file.status)
    );
    processFilesSequentially(uploadingFiles);
  };

  useEffect(() => {
    if (processedFiles && processedFiles >= files.length) {
        setDisableUpload(false);
        setUploadButtonText('Upload Files');
        setProcessedFiles(0);
    }
  }, [processedFiles])

  // const singleFileUploadOld = (file) => {
  //   var base64 = '';
  //   var reader = new FileReader();
  //   reader.onload = function(event) {
  //     base64 = event.target.result;
  //     setFileData(base64);
  //   };
  //   reader.readAsDataURL(file);
  //   setCurrentFile(file);
  //   setFileStatus([...fileStatus, { uuid: file.uuid, status: 'loading' }]);
  // };

  return type === 'image' ? (
    <ImageContainer
      ref={dropzoneRef}
      noClick
      noKeyboard
      style={isDragActive ? { background: colors.lighterGrey } : null}
      {...getRootProps({ className: 'dropzone' })}
    >
      <input {...getInputProps()} />
      <DropzoneText>{text}</DropzoneText>
      <Button text={buttonText} onClick={openDialog} />
      <DropzoneSubtext>{subtext}</DropzoneSubtext>
      {additionalText ? (
        <DropzoneAddText>{additionalText}</DropzoneAddText>
      ) : (
        <></>
      )}
      <UploadedFiles>
        {acceptedFiles.length >= 1 || fileRejections.length >= 1
          ? fileDisplay()
          : null}
      </UploadedFiles>
    </ImageContainer>
  ) : type === 'preview' ? (
    <>
      <FileUploadsContainer
        ref={dropzoneRef}
        noClick
        noKeyboard
        style={isDragActive ? { background: colors.lighterGrey } : null}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <DropzoneText>{text}</DropzoneText>
        <Button text={buttonText} onClick={openDialog} />
        {/* <UploadedFiles>
        {acceptedFiles.length >= 1 || fileRejections.length >= 1
          ? fileDisplay()
          : null}
      </UploadedFiles> */}
        {fileRejections.length >= 1 && (
          <RejectedFilesContainer>
            {fileRejections.map((file, index) => {
              return (
                <RejectedFile key={index}>
                  &#10008; <strong>{file?.file?.name}</strong> (
                  {generateFileSize(file?.file?.size, 2)} MB): File is larger
                  than {maxMB}
                  MB
                </RejectedFile>
              );
            })}
          </RejectedFilesContainer>
        )}
      </FileUploadsContainer>
      {files && files.length > 0 && (
        <PreviewsContainer>
          {files.map((file, index) => {
            const matching = fileStatus.find((f) => file.uuid === f.uuid);
            const status = matching?.status;
            const message = matching?.message;
            return (
              <PreviewContainer key={index} status={status}>
                <ImagePreview
                  style={{ backgroundImage: `url(${generateImage(file)})` }}
                />
                <ImageInfo>
                  <FileName>{file.name}</FileName>
                  <FileSize>{generateFileSize(file.size)} MB</FileSize>
                </ImageInfo>
                <ActionContainer>
                  {status && status === 'loading' ? (
                    <ActionDisplay status={status}>
                      <span>Uploading...</span>
                    </ActionDisplay>
                  ) : status && status === 'complete' ? (
                    <ActionDisplay status={status}>
                      <img
                        src={check}
                        alt="check"
                        data-image={`check-${index}`}
                      />
                      <span>Uploaded</span>
                    </ActionDisplay>
                  ) : status && status === 'error' ? (
                    <ActionDisplay status={status}>
                      <img
                        src={errorCircleRed}
                        alt="error"
                        data-image={`error-${index}`}
                      />
                      <span>Error</span>
                    </ActionDisplay>
                  ) : (
                    <>
                      <button onClick={() => processFile(file)}>
                        <UploadIcon
                          src={download}
                          alt="Upload"
                          title="Upload"
                          data-image={`upload-${index}`}
                        />
                      </button>
                      <button onClick={() => removeFile(file)} title="Remove">
                        <RemoveIcon
                          src={times}
                          alt="Remove"
                          data-image={`remove-${index}`}
                        />
                      </button>
                    </>
                  )}
                </ActionContainer>
                {status && status === 'loading' && (
                  <LoaderContainer>
                    <LoaderBar />
                  </LoaderContainer>
                )}
                {message && status && status === 'error' ? (
                  <div className="error-message">{message}</div>
                ) : (
                  ''
                )}
              </PreviewContainer>
            );
          })}
        </PreviewsContainer>
      )}
      {showMaxFiles && (
        <MaxFilesContainer>
          <InfoIcon src={info} alt="info" data-image={`info`} />
          <p>Maximum of 5 uploads at a time</p>
          <CloseIcon
            src={times}
            alt="close"
            onClick={() => setShowMaxFiles(false)}
            data-image={`close-message`}
          />
        </MaxFilesContainer>
      )}
      <ButtonsRowContainer style={{ width: '100%' }}>
        <ButtonContainer>
          <Button
            text={'Close'}
            onClick={() => hide()}
            theme={ButtonThemes.cancel}
          />
          <Button text={uploadButtonText} onClick={() => uploadAllFiles()} disabled={disableUpload} />
        </ButtonContainer>
      </ButtonsRowContainer>
    </>
  ) : (
    <FileContainer
      ref={dropzoneRef}
      noClick
      noKeyboard
      style={isDragActive ? { background: colors.lighterGrey } : null}
      {...getRootProps({ className: 'dropzone' })}
    >
      <input {...getInputProps()} />
      <DropzoneText>{text}</DropzoneText>
      <Button text={buttonText} onClick={openDialog} />
      <DropzoneSubtext>{subtext}</DropzoneSubtext>
      <UploadedFiles>
        {acceptedFiles.length >= 1 || fileRejections.length >= 1
          ? fileDisplay()
          : null}
      </UploadedFiles>
    </FileContainer>
  );
};

const LoaderContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  border: 0px solid transparent;
  padding: 1.5px 0;
`;
const loadingAnimation = keyframes`
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }`;

const LoaderBar = styled.div`
  position: absolute;
  border-radius: 50px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: ${colors.green};
  width: 0;
  animation: ${loadingAnimation} 2s linear infinite;
`;

const PreviewsContainer = styled.div`
  width: 100%;
  margin-top: 15px;
`;

const ImagePreview = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  img {
    width: 100%;
    height: 100%;
  }
`;

const ImageInfo = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  margin-left: 15px;
`;

const ActionContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 65px;
  button {
    width: 30px;
    height: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background: ${colors.lighterGrey};
    border-radius: 50px;
    &:hover {
      background: ${colors.lightGrey};
    }
    &:first-child {
      margin-right: 5px;
    }
  }
`;

const ActionDisplay = styled.div`
  color: ${(props) =>
    props.status && props.status === 'complete'
      ? colors.green
      : props.status && props.status === 'error'
        ? messageColors.error
        : colors.paleGrey};
  font-size: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  img {
    margin-right: 5px;
    width: 10px;
    height: 10px;
  }
`;

const UploadIcon = styled.img`
  transform: rotate(180deg);
  width: 12px;
  height: 12px;
`;

const RemoveIcon = styled.img`
  width: 10px;
  height: 10px;
`;

const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 6px;
  border: ${(props) =>
    props.status && props.status === 'complete'
      ? `1px solid ${colors.green}`
      : props.status && props.status === 'error'
        ? `1px solid ${messageColors.error}`
        : `1px solid ${colors.hoverLightGrey}`};
  background: ${(props) =>
    props.status && props.status === 'complete'
      ? messageColors.successBackground
      : props.status && props.status === 'error'
        ? messageColors.errorBackground
        : 'white'};
  margin-bottom: 5px;
  padding: 8px;
  .error-message {
    flex-basis: 100%;
    font-size: ${fonts.error};
    color: ${messageColors.error};
  }
`;

const FileName = styled.p`
  max-width: 270px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.darkGrey};
  font-weight: ${fonts.semiBold};
`;

const FileSize = styled.p`
  max-width: 325px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  color: ${colors.paleGrey};
`;

const ImageContainer = styled.div`
  @media ${maxDevice.sideNav} {
    justify-content: center;
    width: 100%;
    margin-right: 0px;
  }
  flex: 1 1 auto;
  position: relative;
  min-width: 200px;
  max-width: 250px;
  background: white;
  margin-right: 30px;
  border-radius: 5px;
  border: 1px dashed ${colors.paleGrey};
  padding: 20px;
  height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  button {
    width: 150px;
    background: ${colors.paleGrey};
  }
`;

const DropzoneText = styled.p`
  font-size: 13px;
  color: ${colors.paleGrey};
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const DropzoneSubtext = styled.p`
  font-size: 11px;
  font-weight: ${fonts.regular};
  margin-top: 8px;
  color: ${colors.paleGrey};
`;

const DropzoneAddText = styled.p`
  font-size: 11px;
  font-weight: ${fonts.regular};
  color: ${colors.paleGrey};
`;

const UploadedFiles = styled.div`
  position: absolute;
  bottom: 13px;
  width: 100%;
  font-size: 11px;
  text-align: center;
  max-width: 280px;
`;

const MaxFilesContainer = styled.div`
  border: 1px solid ${colors.lightGrey};
  background: ${colors.lighterGrey};
  padding: 10px 15px;
  border-radius: 3px;
  width: 100%;
  margin: 8px 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  p {
    color: ${colors.darkGrey};
    font-size: 13px;
    flex: 1 1 auto;
    text-align: left;
  }
`;

const InfoIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 20px;
`;

const CloseIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const FileContainer = styled.div`
  background: white;
  border-radius: 5px;
  min-width: 200px;
  max-width: 300px;
  border: 1px dashed ${colors.paleGrey};
  padding: 15px 15px 10px 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  ${DropzoneText} {
    border: none;
    padding: 0px;
    margin: 0 0 10px 0;
  }
  button {
    width: 150px;
    background: ${colors.paleGrey};
  }
  ${DropzoneSubtext} {
    margin: 10px 0 0 0;
  }
  ${UploadedFiles} {
    flex: 1 1 auto;
    position: static;
    width: 100%;
    font-size: 11px;
    text-align: center;
    margin-top: 5px;
  }
`;

const FileUploadsContainer = styled.div`
  background: white;
  border-radius: 5px;
  width: 100%;
  border: 1px dashed ${colors.paleGrey};
  padding: 15px 15px 10px 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  ${DropzoneText} {
    border: none;
    padding: 0px;
    margin: 0 0 10px 0;
  }
  button {
    width: 150px;
    background: ${colors.paleGrey};
  }
  /* ${DropzoneSubtext} {
    margin: 10px 0 0 0;
  }
  ${UploadedFiles} {
    flex: 1 1 auto;
    position: static;
    width: 100%;
    font-size: 11px;
    text-align: center;
    margin-top: 5px;
  } */
`;

const AcceptedFile = styled.p`
  color: ${toastColors.success};
  padding: 0 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const RejectedFile = styled.p`
  color: ${messageColors.error};
  padding: 0 8px;
`;

const RejectedFilesContainer = styled.div`
  margin-top: 10px;
  ${RejectedFile} {
    padding: 3px 5px;
    text-align: left;
    font-size: 13px;
  }
`;

DropZone.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  subtext: PropTypes.string,
  maxMB: PropTypes.number,
  type: PropTypes.string,
  accepted: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  multiple: PropTypes.bool,
  dispatchFunction: PropTypes.func,
  setFileData: PropTypes.func,
  setCurrentFile: PropTypes.func,
  clearFiles: PropTypes.bool,
  uploadedFileId: PropTypes.string,
  failedFileId: PropTypes.string,
  failedFileMessage: PropTypes.string,
  hide: PropTypes.func,
  additionalText: PropTypes.string,
};

export default DropZone;
