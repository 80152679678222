/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import AvatarEditor from 'react-avatar-editor';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Button, DropZone, FormChunk } from '../../components';
import {
  updateUserInfo,
  setUploadedProfilePicture,
  updateProfilePicture,
  removeProfilePicture,
  toggleGrantSupportAccess,
  updateApplicationSettings,
  updateNotificationPreferences,
} from '../../store/actions';
import {
  set1,
  set2,
  set3,
  set4,
  set5,
  checkboxGreen,
  square,
} from '../../assets';
import {
  GetCurrencies,
  SetDefaultItem,
  GetCountries,
  GetStates,
  CheckHasValue,
  states,
  amrOrientationPreferences,
  ErrorNotification,
} from '../../utils';
import { ButtonThemes } from '../../styles/themes';
import { colors, maxDevice, fonts } from '../../styles/variables';

const UserPreferencesContainer = ({ isConsumer }) => {
  const dispatch = useDispatch();
  const {
    avatar,
    uploadedPic,
    user,
    loadedConfigs,
    householdPageChoices,
    canEditName,
    canEditEmail,
    canEditOrgName,
    canSetSubtitle,
    canSetRounding,
    preferences,
    countries,
    currencies,
    supportAccess,
    canSetAutoSync,
  } = useSelector((state) => ({
    avatar: state.user.user.avatar,
    uploadedPic: state.user.profilePicture,
    user: state.user.user,
    loadedConfigs: state.configs.loadedConfigs,
    householdPageChoices: state.configs.householdPageChoices,
    canEditName: state.user.canEditName,
    canEditEmail: state.user.canEditEmail,
    canEditOrgName: state.user.canEditOrgName,
    canSetSubtitle: state.user.canSetSubtitle,
    canSetRounding: state.user.canSetRounding,
    preferences: state.user.user.preferences,
    countries: state.configs.countries,
    currencies: state.configs.currencies,
    supportAccess: state.user.supportAccess,
    canSetAutoSync: state.user.canSetAutoSync,
  }));
  const [contactInfo, setContactInfo] = useState({
    displayName: '',
    first_name: '',
    last_name: '',
    email: '',
    organization: '',
    designation: '',
    suffix: '',
    phone_number: '',
    fax_number: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    website: '',
  });
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [stateDisabled, setStateDisabled] = useState(false);
  const [avatarSubtitle, setAvatarSubtitle] = useState();
  const [instrumentRounding, setInstrumentRounding] = useState();
  const [sideMenuDefault, setSideMenuDefault] = useState();
  const [defaultCountry, setDefaultCountry] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [editor, setEditor] = useState();
  const [editorSet, setEditorSet] = useState(false);
  const [iconSet, setIconSet] = useState();
  const [image, setImage] = useState();
  const [positionValues, setPositionValues] = useState();
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [clearAvatar, setClearAvatar] = useState(false);
  const [amrOrientationPref, setAmrOrientationPref] = useState();
  const [householdPageOptions, setHouseholdPageOptions] = useState([]);
  const [householdStartPage, setHouseholdStartPage] = useState();
  const [accountSettings, setAccountSettings] = useState({
    end_date: null,
    managers: {
      billing: [],
      team: [],
    },
    state: null,
    state_display: null,
    type: null,
    type_display: null,
    loading: true,
  });
  const [scale, setScale] = useState(1);
  const [accessGranted, setAccessGranted] = useState(false);
  const [supportEnd, setSupportEnd] = useState();
  const [errorFields, setErrorFields] = useState([]);
  const [contactInfoErrorMessages, setContactInfoErrorMessages] = useState([]);
  const [notificationSettings, setNotificationSettings] = useState([
    {
      communication_type: 'email',
      notification_type: 'SUBADVISOR',
      is_silenced: true,
    },
    {
      communication_type: 'inapp',
      notification_type: 'SUBADVISOR',
      is_silenced: true,
    },
    {
      communication_type: 'email',
      notification_type: 'CLIENT_INTERVIEW_STARTED',
      is_silenced: true,
    },
    {
      communication_type: 'inapp',
      notification_type: 'CLIENT_INTERVIEW_STARTED',
      is_silenced: true,
    },
    {
      communication_type: 'email',
      notification_type: 'CLIENT_INTERVIEW_CLOSED',
      is_silenced: true,
    },
    {
      communication_type: 'inapp',
      notification_type: 'CLIENT_INTERVIEW_CLOSED',
      is_silenced: true,
    },
    {
      communication_type: 'email',
      notification_type: 'CLIENT_INTERVIEW_GENERATE',
      is_silenced: true,
    },
    {
      communication_type: 'inapp',
      notification_type: 'CLIENT_INTERVIEW_GENERATE',
      is_silenced: true,
    },
    {
      communication_type: 'email',
      notification_type: 'CLIENT_INTERVIEW_NEW_LEAD',
      is_silenced: true,
    },
    {
      communication_type: 'inapp',
      notification_type: 'CLIENT_INTERVIEW_NEW_LEAD',
      is_silenced: true,
    },
    {
      communication_type: 'email',
      notification_type: 'QUESTIONNAIRE_COMPLETE',
      is_silenced: true,
    },
    {
      communication_type: 'inapp',
      notification_type: 'QUESTIONNAIRE_COMPLETE',
      is_silenced: true,
    }
  ]);
  const [autoSync, setAutoSync] = useState(false);

  const avatarSubtitleOptions = [
    { value: 'none', label: 'None' },
    { value: 'default', label: 'Default' },
    { value: 'age', label: 'Age' },
    { value: 'date', label: 'Date of Birth' },
  ];

  const instrumentRoundingOptions = [
    { value: 'none', label: 'None' },
    { value: 'default', label: 'Default' },
    { value: 'ten', label: 'Ten' },
    { value: 'hundred', label: 'Hundred' },
    { value: 'thousand', label: 'Thousand' },
  ];

  const dateFormatOptions = [
    { value: 'mdy', label: 'mm/dd/yyyy' },
    { value: 'ymd', label: 'yyyy/mm/dd' },
    { value: 'dmy', label: 'dd/mm/yyyy' },
  ];

  useEffect(() => {
    if (supportAccess) {
      setAccessGranted(supportAccess.enabled);
      setSupportEnd(supportAccess.expires);
    }
  }, [supportAccess]);

  useEffect(() => {
    if (user && user.name) {
      let displayName = user.name;
      if (user.suffix !== '' && user.suffix !== null) {
        displayName = `${user.name}, ${user.suffix}`;
      }
      setContactInfo({
        ...contactInfo,
        displayName: displayName,
        name: user.name,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        organization: user.customer_name_override_display,
        designation: user.designation,
        suffix: user.suffix,
        phone_number: user.phone_number,
        fax_number: user.fax_number,
        address: user.address,
        address2: user.address2,
        city: user.city,
        state: SetDefaultItem(states, user.state, setSelectedState),
        zip: user.zip,
        country: SetDefaultItem(countries, user.country, setSelectedCountry),
        website: user.website,
      });
      setImage(avatar);
      setScale(1);
      setLoadingAvatar(false);
      setClearAvatar(true);
      setAccountSettings({
        ...user?.customer?.subscription,
        loading: false,
        showAllBilling: false,
        showAllTeam: false,
      });
    }
  }, [user, avatar, countries, states]);

  useEffect(() => {
    if (preferences && preferences.notifications) {
      if (preferences.notifications.length >= 1) {
        setNotificationSettings(preferences.notifications);
      }
    }
    if (preferences && preferences.auto_sync) {
      setAutoSync(preferences.auto_sync);
    }
  }, [preferences]);

  useEffect(() => {
    if (selectedCountry && selectedCountry.value !== 'US') {
      setSelectedState(null);
      setStateDisabled(true);
    } else {
      SetDefaultItem(states, user.state, setSelectedState);
      setStateDisabled(false);
    }
  }, [selectedCountry, user.state]);

  useEffect(() => {
    if (uploadedPic) {
      setImage(uploadedPic);
      setClearAvatar(false);
    }
  }, [uploadedPic]);

  useEffect(() => {
    if (preferences && loadedConfigs) {
      SetDefaultItem(currencies, preferences.currency, setSelectedCurrency);
      SetDefaultItem(countries, preferences.country, setDefaultCountry);
      if (preferences.date_format) {
        setSelectedDate(
          matchSelectItem(
            dateFormatOptions,
            preferences.date_format.toLowerCase()
          )
        );
      }
      if (preferences.amr) {
        let label = 'Hide';
        if (preferences.amr.tray) {
          label = 'Show';
        }
        setSideMenuDefault({
          label: label,
          value: preferences.amr.tray,
        });
        setIconSet(preferences.amr.icon_set);
        let matchingSubtitle = { value: 'default', label: 'Default' };
        if (preferences.amr.member_subtitle) {
          matchingSubtitle = avatarSubtitleOptions.find(
            (option) => option.value === preferences.amr.member_subtitle
          );
        }
        setAvatarSubtitle(matchingSubtitle);
      }
      let matchingRounding = { value: 'default', label: 'Default' };
      if (preferences.amr.rounding) {
        matchingRounding = instrumentRoundingOptions.find(
          (option) => option.value === preferences.amr.rounding
        );
      }
      setInstrumentRounding(matchingRounding);
      let matchingAmrPref = amrOrientationPreferences[0];
      if (preferences.amr.orientation) {
        matchingAmrPref = amrOrientationPreferences.find(
          (option) => option.value === preferences.amr.orientation
        );
      }
      setAmrOrientationPref(matchingAmrPref);
      if (householdPageChoices && householdPageChoices.length > 0) {
        setHouseholdPageOptions(householdPageChoices);
        const matchedStarting = householdPageChoices.find(
          (option) => option?.value === preferences?.default_hh_page
        );
        setHouseholdStartPage(matchedStarting);
      }
    }
  }, [preferences, currencies, countries, loadedConfigs]);

  const matchSelectItem = (list, label) => {
    return list.find((item) => item.label === label);
  };

  const toggleAutoSync = (e) => {
    setAutoSync(!e.value);
  };

  const contactInfoInputs = [
    {
      name: 'displayName',
      placeholder: 'Display Name',
      type: 'text',
      width: '100%',
      required: false,
      disabled: true,
      lastpassIgnore: true,
    },
    {
      name: 'first_name',
      placeholder: 'First Name',
      type: 'text',
      width: '50%',
      required: true,
      disabled: !canEditName,
      lastpassIgnore: true,
    },
    {
      name: 'last_name',
      placeholder: 'Last Name',
      type: 'text',
      width: '50%',
      required: true,
      disabled: !canEditName,
      lastpassIgnore: true,
    },
    {
      name: 'email',
      placeholder: 'Email',
      type: 'email',
      width: '50%',
      required: false,
      disabled: !canEditEmail,
    },
    //Everything below is hidden
    {
      name: 'organization',
      placeholder: 'Organization',
      type: 'text',
      width: '50%',
      required: false,
      disabled: !canEditOrgName,
      lastpassIgnore: true,
      is_hidden: isConsumer,
    },
    {
      name: 'designation',
      placeholder: 'Title',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
      is_hidden: isConsumer,
    },
    {
      name: 'suffix',
      placeholder: 'Suffix',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
      is_hidden: isConsumer,
    },
    {
      name: 'phone_number',
      placeholder: 'Phone',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
      is_hidden: isConsumer,
    },
    {
      name: 'fax_number',
      placeholder: 'Fax',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
      is_hidden: isConsumer,
    },
    {
      name: 'address',
      placeholder: 'Address',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
      is_hidden: isConsumer,
    },
    {
      name: 'address2',
      placeholder: 'Address Additional',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
      is_hidden: isConsumer,
    },
    {
      name: 'city',
      placeholder: 'City',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
      margin: '0 8px 10px 0px',
      is_hidden: isConsumer,
    },
    {
      name: 'state',
      placeholder: 'State',
      type: 'select',
      width: 'calc(30% - 8px)',
      selectedOption: selectedState,
      onChange: (e) => setSelectedState(e),
      options: GetStates(states),
      required: false,
      isDisabled: stateDisabled,
      margin: '0 8px 10px 0px',
      is_hidden: isConsumer,
    },
    {
      name: 'zip',
      placeholder: 'Zip',
      type: 'number',
      // width: '20%',
      width: 'calc(20% - 8px)',
      required: false,
      lastpassIgnore: true,
      is_hidden: isConsumer,
    },
    {
      name: 'country',
      placeholder: 'Country',
      type: 'select',
      width: '100%',
      selectedOption: selectedCountry,
      onChange: (e) => setSelectedCountry(e),
      options: GetCountries(countries),
      required: true,
      is_hidden: isConsumer,
    },
    {
      name: 'website',
      placeholder: 'Website',
      type: 'text',
      width: '50%',
      required: false,
      lastpassIgnore: true,
      is_hidden: isConsumer,
    },
  ];

  const iconOptions = [
    {
      value: 'set-01',
      src: set1,
    },
    {
      value: 'set-02',
      src: set2,
    },
    {
      value: 'set-03',
      src: set3,
    },
    {
      value: 'set-04',
      src: set4,
    },
    {
      value: 'set-05',
      src: set5,
    },
  ];

  //Avatar subtitle and Instrument Rounding should be hidden unless team settings has them enabled

  const iconSettings = [
    {
      name: 'householdAvatar',
      placeholder: 'Household Avatar Subtitle',
      type: 'select',
      selectedOption: avatarSubtitle,
      onChange: (e) => setAvatarSubtitle(e),
      options: avatarSubtitleOptions,
      width: '50%',
      margin: '0 8px 0 -8px',
      is_hidden: !canSetSubtitle,
    },
    {
      name: 'householdAvatar',
      placeholder: 'Instrument Value Rounding',
      type: 'select',
      selectedOption: instrumentRounding,
      onChange: (e) => setInstrumentRounding(e),
      options: instrumentRoundingOptions,
      width: '50%',
      margin: canSetSubtitle ? '0 -8px 0 8px' : '0 0 0 -8px',
      is_hidden: !canSetRounding,
    },
    // {
    //   name: 'sideMenu',
    //   placeholder: 'Side Menu Default',
    //   type: 'select',
    //   selectedOption: sideMenuDefault,
    //   onChange: (e) => setSideMenuDefault(e),
    //   options: [
    //     { value: 'false', label: 'Hide' },
    //     { value: 'true', label: 'Show' },
    //   ],
    //   width: '50%',
    //   margin: '0 -8px 0 8px',
    // },
  ];

  const applicationSettings = [
    {
      name: 'defaultCountry',
      placeholder: 'Default Country',
      type: 'select',
      selectedOption: defaultCountry,
      onChange: (e) => setDefaultCountry(e),
      options: GetCountries(countries),
      width: '50%',
      margin: '0 8px 0 -8px',
      available: true,
    },
    {
      name: 'defaultCurrency',
      placeholder: 'Default Currency',
      type: 'select',
      selectedOption: selectedCurrency,
      onChange: (e) => setSelectedCurrency(e),
      options: GetCurrencies(currencies),
      width: '50%',
      margin: '0 -8px 0 8px',
      available: true,
    },
    {
      name: 'dateFormat',
      placeholder: 'Date/Time Format',
      type: 'select',
      selectedOption: selectedDate,
      onChange: (e) => setSelectedDate(e),
      options: dateFormatOptions,
      width: '50%',
      margin: '10px 8px 0 -8px',
      available: true,
    },
    {
      name: 'amrPreference',
      placeholder: 'Asset-Map Report Print Orientation',
      type: 'select',
      selectedOption: amrOrientationPref,
      onChange: (e) => setAmrOrientationPref(e),
      options: amrOrientationPreferences,
      width: '50%',
      margin: '10px -8px 0 8px',
      available: true,
    },
    {
      name: 'defaultHouseholdPage',
      placeholder: 'Default Household Start Page',
      tooltip:
        'This is the first page you will see when entering a household. Currently defaults to Household Summary.',
      type: 'select',
      selectedOption: householdStartPage,
      onChange: (e) => setHouseholdStartPage(e),
      options: householdPageOptions,
      width: '50%',
      margin: '10px 8px 0 -8px',
      available: true,
    },
    {
      name: 'integration_auto_sync',
      placeholder: 'Integration Auto Sync',
      available: canSetAutoSync,
      tooltip:
        'All integration data will automatically sync if older than 30 days.',
      type: 'toggle',
      onClick: toggleAutoSync,
      width: '50%',
      text: 'Auto Sync Integration Data',
      showLabel: true,
    },
  ];

  const updateContactInfo = (e) => {
    setContactInfo({
      ...contactInfo,
      [e.currentTarget.name]: e.currentTarget.value,
    });
    setErrorFields([]);
    setContactInfoErrorMessages([]);
  };

  const generateSettingsObj = () => {
    let obj = {
      icon_set: iconSet,
      // member_subtitle: avatarSubtitle.value,
      // rounding: instrumentRounding.value,
      is_show_amr_menu: sideMenuDefault.value === 'true',
      default_country: defaultCountry.value,
      currency: selectedCurrency.value,
      date_format: selectedDate.value,
      amr_orientation: amrOrientationPref.value,
      default_hh_page: householdStartPage?.value,
      auto_sync: autoSync,
    };
    if (canSetSubtitle) {
      obj.member_subtitle = avatarSubtitle.value;
    }
    if (canSetRounding) {
      obj.rounding = instrumentRounding.value;
    }
    dispatch(updateApplicationSettings(obj));
  };

  const validateContactInfo = () => {
    //Check that none of values are null or invalid types
    let obj = contactInfo;

    if (selectedState) {
      obj.state = selectedState.value;
    }
    if (selectedCountry) {
      obj.country = selectedCountry.value;
    }
    let fields = [
      {
        field: 'first_name',
        value: contactInfo.first_name,
      },
      {
        field: 'last_name',
        value: contactInfo.last_name,
      },
      {
        field: 'email',
        value: contactInfo.email,
      },
      // {
      //   field: 'phone_number',
      //   value: contactInfo.phone_number,
      // },
      // {
      //   field: 'address',
      //   value: contactInfo.address,
      // },
      // {
      //   field: 'city',
      //   value: contactInfo.city,
      // },
      // {
      //   field: 'country',
      //   value: contactInfo.country,
      // },
    ];
    let checkFields = fields.map((field) => {
      return CheckHasValue(field);
    });
    let errorFields = checkFields.filter((field) => {
      return field.error;
    });
    if (canEditOrgName) {
      obj.customer_name_override = obj.organization;
    }
    if (!canEditEmail) {
      delete obj.email;
    }
    if (!canEditName) {
      delete obj.first_name;
      delete obj.last_name;
    }
    setErrorFields(errorFields);
    if (errorFields && errorFields.length >= 1) {
      setContactInfoErrorMessages(['Missing Required Fields']);
    }

    if (errorFields && errorFields.length === 0) {
      dispatch(updateUserInfo(obj));
    }
  };

  const updateInfo = (type) => {
    if (type === 'contact') {
      return validateContactInfo();
    }
    if (type === 'settings') {
      return generateSettingsObj();
    }
  };

  const updateGrantAccess = () => {
    dispatch(toggleGrantSupportAccess(!accessGranted));
    setAccessGranted(!accessGranted);
  };

  const hasNotificationAttr = (attr) => {
    return Object.keys(attr).length;
  }

  const transformNotifications = (type) => {
    const filtered = notificationSettings.filter(
      (setting) => setting.notification_type === type
    );
    return filtered.reduce((acc, curr) => {
      if (curr) {
        const fieldName = curr.communication_type;
        if (curr.hasOwnProperty('is_silenced')) {
          acc[fieldName] = curr.is_silenced;
        }
      }
      return acc;
    }, {});
  };

  const updateNotificationSettings = () => {
    const SUBADVISOR = transformNotifications('SUBADVISOR');
    const CLIENT_INTERVIEW_STARTED = transformNotifications(
      'CLIENT_INTERVIEW_STARTED'
    );
    const CLIENT_INTERVIEW_GENERATE = transformNotifications(
      'CLIENT_INTERVIEW_GENERATE'
    );
    const CLIENT_INTERVIEW_CLOSED = transformNotifications(
      'CLIENT_INTERVIEW_CLOSED'
    );
    const CLIENT_INTERVIEW_NEW_LEAD = transformNotifications(
      'CLIENT_INTERVIEW_NEW_LEAD'
    );
    const CLIENT_PORTAL_ACCOUNT_CREATED = transformNotifications(
      'CLIENT_PORTAL_ACCOUNT_CREATED'
    );
    const CLIENT_PORTAL_INVITE_ACCEPTED = transformNotifications(
      'CLIENT_PORTAL_INVITE_ACCEPTED'
    );
    const QUESTIONNAIRE_COMPLETE = transformNotifications(
      'QUESTIONNAIRE_COMPLETE'
    );

    const obj = {
      SUBADVISOR,
      CLIENT_INTERVIEW_STARTED,
      CLIENT_INTERVIEW_GENERATE,
      CLIENT_INTERVIEW_CLOSED,
      CLIENT_INTERVIEW_NEW_LEAD,
    };

    /*
      This is when the notification preference is not present from the api response.
      Usually this is when the Feature Flag is turn Off.
      When Feature Flag is set to true it should be included to obj variable.
    */
    const notificationPrefs = {
      'CLIENT_PORTAL_ACCOUNT_CREATED': CLIENT_PORTAL_ACCOUNT_CREATED,
      'CLIENT_PORTAL_INVITE_ACCEPTED': CLIENT_PORTAL_INVITE_ACCEPTED,
      'QUESTIONNAIRE_COMPLETE': QUESTIONNAIRE_COMPLETE,
    }
    for (const [key, value] of Object.entries(notificationPrefs)) {
      if (hasNotificationAttr(value)) {
          obj[key] = value;
      }
    }
    dispatch(updateNotificationPreferences(obj));
  };

  const removeAvatar = () => {
    setImage(null);
    dispatch(removeProfilePicture());
    //Dispatch PUT call to remove Avatar and then use default avatar
  };

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const updateCheckbox = (notification_type, communication_type) => {
    let newSettings = notificationSettings.map((setting) => {
      if (
        setting.notification_type === notification_type &&
        setting.communication_type === communication_type
      ) {
        setting.is_silenced = !setting.is_silenced;
      }
      return setting;
    });
    setNotificationSettings(newSettings);
  };

  const labelMapping = {
    SUBADVISOR: 'Added as Sub-Advisor',
    CLIENT_INTERVIEW_STARTED: 'Interview Started',
    CLIENT_INTERVIEW_GENERATE: 'Interview Completed',
    CLIENT_INTERVIEW_CLOSED: 'Interview Incomplete',
    CLIENT_INTERVIEW_NEW_LEAD: 'New Lead',
    CLIENT_PORTAL_ACCOUNT_CREATED:
      'Client Portal Account Created',
    CLIENT_PORTAL_INVITE_ACCEPTED:
      'Client Portal Invitation Accepted',
    QUESTIONNAIRE_COMPLETE:
      'Questionnaire Completed',
  };

  const generateNotificationTable = () => {
    const notificationOptions = notificationSettings.reduce((acc, curr) => {
      const type = curr.notification_type;
      if (!acc.find((v) => v.notification_type === type)) {
        let label = type;
        if (labelMapping[type]) {
          label = labelMapping[type];
        }
        const obj = { label: label, notification_type: type };
        acc.push(obj);
      }
      return acc;
    }, []);
    return notificationOptions;
  };

  const determineCheck = (notification_type, communication_type) => {
    // eslint-disable-next-line array-callback-return
    let matched = notificationSettings.find((setting) => {
      return (
        setting.notification_type === notification_type &&
        setting.communication_type === communication_type
      );
    });

    try {
      if (matched !== undefined) {
        if (matched.is_silenced) {
          return square;
        } else {
          return checkboxGreen;
        }
      } else {
        return checkboxGreen;
      }
    } catch (e) {
      return checkboxGreen;
    }
  };

  const updateAccountSettings = (key, value) => {
    setAccountSettings({
      ...accountSettings,
      [key]: value,
    });
  };

  const checkIsHidden = () => {
    if (isConsumer) return true;
    const subscriptionType = user?.customer?.subscription?.type;
    if (subscriptionType && subscriptionType === 'subscription') {
      return false;
    }
    return true;
  };

  const userSettings = [
    {
      header: 'Contact Information',
      inputs: contactInfoInputs.filter((input) => !input.is_hidden),
      value: contactInfo,
      onChange: updateContactInfo,
      hasErrorDisplay: true,
      errorMessages: contactInfoErrorMessages,
      borderBottom: !isConsumer,
      button: {
        text: 'Update',
        onClick: updateInfo,
        value: 'contact',
      },
    },
    // {
    //   header: 'Password',
    //   id: 'password',
    //   inputs: passwordInputs,
    //   value: password,
    //   onChange: updatePassword,
    //   hasErrorDisplay: true,
    //   errorMessages: passwordErrorMessages,
    //   button: {
    //     text: 'Update',
    //     onClick: updateInfo,
    //     value: 'password',
    //   },
    // },
    {
      type: 'icon',
      header: 'Application Settings',
      formText: 'Asset-Map Member Icons',
      formTextLink: {
        text: 'More Info',
        link: 'https://usersupport.asset-map.com/en/articles/5173161-avatar-color-and-shape-options',
      },
      inputs: iconOptions,
      is_hidden: isConsumer,
      value: iconSet,
      onClick: setIconSet,
      borderBottom: false,
      style: { marginBottom: '-20px' },
    },
    {
      type: 'noHeader',
      is_hidden: isConsumer,
      inputs: iconSettings,
      borderBottom: false,
      style: {
        margin: '15px 8px',
        paddingBottom: '30px',
        borderBottom: `1px solid ${colors.lightGrey}`,
      },
    },
    {
      type: 'noHeader',
      inputs: applicationSettings.filter((input) => input.available),
      is_hidden: isConsumer,
      style: {
        margin: '10px 8px 20px 8px',
      },
      button: {
        text: 'Update',
        onClick: updateInfo,
        value: 'settings',
      },
      value: {
        integration_auto_sync: autoSync,
      },
    },
    {
      type: 'account',
      header: 'Account Info',
      is_hidden: checkIsHidden(),
      extraData: accountSettings,
      onClick: updateAccountSettings,
    },
    {
      type: 'countdown',
      header: 'Support',
      is_hidden: isConsumer,
      formText:
        'This button will grant access to Asset-Map Support for seven days unless the permission is revoked by the user.',
      extraData: { supportEnd },
      button: {
        text: accessGranted ? 'Revoke Access' : 'Grant Access',
        theme: accessGranted ? ButtonThemes.error : ButtonThemes.primary,
        onClick: updateGrantAccess,
      },
    },
    {
      header: 'Manage Deleted Households',
      formText: 'You can restore deleted households from up to 30 days ago.',
      is_hidden: isConsumer,
      link: {
        text: 'View Deleted Households',
        link: '/member/restore_households',
        isExternal: false,
      },
    },
    {
      type: 'table',
      headers: [
        { text: 'Type', align: 'left' },
        { text: 'Email', align: 'center' },
        { text: 'In-App', align: 'center' },
      ],
      header: 'Notifications',
      is_hidden: isConsumer,
      inputs: generateNotificationTable(),
      onClick: updateCheckbox,
      checkFunction: determineCheck,
      borderBottom: false,
      button: {
        text: 'Update',
        onClick: updateNotificationSettings,
        value: 'notification',
      },
    },
  ];

  const updateAvatar = () => {
    const rect = editor.getCroppingRect();
    const updated = {
      x0: parseFloat(rect.x.toFixed(4)),
      y0: parseFloat(rect.y.toFixed(4)),
      x1: parseFloat((rect.x + rect.width).toFixed(4)),
      y1: parseFloat((rect.y + rect.height).toFixed(4)),
    };

    if (uploadedPic) {
      // img = editor.getImageScaledToCanvas().toDataURL();
      const obj = {
        crop: updated,
        image: image,
      };
      setLoadingAvatar(true);
      dispatch(updateProfilePicture(obj));
    } else {
      return ErrorNotification('Upload New Image');
      // const convertedImg = editor.getImage().toDataURL();
      // const obj = {
      //   crop: updated,
      //   image: convertedImg,
      // };
      // setLoadingAvatar(true);
      // dispatch(updateProfilePicture(obj));
    }
  };

  const setEditorRef = (editor) => {
    if (editor && !editorSet) {
      setEditor(editor);
      setEditorSet(true);
    }
  };

  return (
    <ContainerLayout>
      <LeftContent>
        {userSettings.map((form, index) => {
          return (
            !form.is_hidden && (
              <FormChunk
                key={index}
                type={form.type}
                formText={form.formText}
                formTextLink={form.formTextLink}
                header={form.header}
                inputs={form.inputs}
                value={form.value}
                onChange={form.onChange}
                onClick={form.onClick}
                button={form.button}
                link={form.link}
                borderBottom={form.borderBottom}
                style={form.style}
                extraData={form.extraData}
                headers={form.headers}
                errorFields={errorFields}
                hasErrorDisplay={form.hasErrorDisplay}
                errorMessages={form.errorMessages}
                checkFunction={form.checkFunction}
              />
            )
          );
        })}
      </LeftContent>
      <RightContent>
        <FormContainer>
          <FormHeader>Profile Picture</FormHeader>
          <ProfilePictureContainer>
            <UploadContainer>
              <DropZone
                dispatchFunction={setUploadedProfilePicture}
                clearFiles={clearAvatar}
              />
            </UploadContainer>
            <ModifyContainer>
              <PreviewContainer>
                <AvatarEditor
                  ref={setEditorRef}
                  image={image}
                  width={150}
                  height={150}
                  border={1}
                  borderRadius={100}
                  position={positionValues}
                  onPositionChange={(e) => setPositionValues(e)}
                  color={[0, 0, 0, 0.6]}
                  scale={parseFloat(scale)}
                  rotate={0}
                />
                <SizeSliderContainer>
                  <Slider
                    name="scale"
                    type="range"
                    onChange={(e) => handleScale(e)}
                    min="1"
                    max="3"
                    step="0.1"
                    value={parseFloat(scale)}
                    // defaultValue="1"
                  />
                </SizeSliderContainer>
                <ButtonColumn>
                  <Button
                    text="Update"
                    onClick={updateAvatar}
                    showLoading={loadingAvatar}
                    loadingText={'Updating'}
                  />
                  <Button text="Remove" onClick={removeAvatar} />
                </ButtonColumn>
              </PreviewContainer>
            </ModifyContainer>
          </ProfilePictureContainer>
        </FormContainer>
      </RightContent>
    </ContainerLayout>
  );
};

const ProfilePictureContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
`;

const UploadContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-right: none;
    justify-content: center;
    width: 100%;
  }
  flex: 1 1 auto;
  width: 250px;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid ${colors.lightGrey};
`;

const ModifyContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-right: none;
    margin-bottom: 40px;
  }
  flex: 1 1 auto;
  width: 200px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const PreviewContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-right: none;
    margin-left: 0px;
  }
  margin-left: 30px;
  padding: 0 0 20px 0;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const SizeSliderContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
`;

const height = '15px';
const thumbHeight = 15;
const trackHeight = '8px';

const upperColor = colors.lightGrey;
const lowerColor = colors.lightGrey;
const thumbColor = colors.darkGrey;
const thumbHoverColor = colors.green;
const upperBackground = `linear-gradient(to bottom, ${upperColor}, ${upperColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;
const lowerBackground = `linear-gradient(to bottom, ${lowerColor}, ${lowerColor}) 100% 50% / 100% ${trackHeight} no-repeat transparent`;

const makeLongShadow = (color, size) => {
  let i = 18;
  let shadow = `${i}px 0 0 ${size} ${color}`;

  for (; i < 706; i++) {
    shadow = `${shadow}, ${i}px 0 0 ${size} ${color}`;
  }

  return shadow;
};

const Slider = styled.input`
  overflow: hidden;
  display: block;
  appearance: none;
  width: 100%;
  padding: 1px;
  background: transparent;
  height: ${height};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${height};
    background: ${lowerBackground};
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    height: ${thumbHeight}px;
    width: ${thumbHeight}px;
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    top: 50%;
    box-shadow: ${makeLongShadow(upperColor, '-10px')};
    transform: translateY(-50%);
    transition: background-color 150ms;
  }

  &::-moz-range-track,
  &::-moz-range-progress {
    width: 100%;
    height: ${height};
    background: ${upperBackground};
  }

  &::-moz-range-progress {
    background: ${lowerBackground};
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: ${height};
    border: 0;
    /* color needed to hide track marks */
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background: ${lowerBackground};
  }

  &::-ms-fill-upper {
    background: ${upperBackground};
  }

  &::-ms-thumb {
    appearance: none;
    height: ${thumbHeight};
    width: ${thumbHeight};
    background: ${thumbColor};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    /* IE Edge thinks it can support -webkit prefixes */
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-moz-range-thumb {
      background-color: ${thumbHoverColor};
    }
    &::-ms-thumb {
      background-color: ${thumbHoverColor};
    }
  }
`;

// const ImagePreview = styled.div`
//   background: white;
//   height: 150px;
//   width: 150px;
//   border: 1px solid ${colors.lightGrey};
//   border-radius: 50%;
//   background-repeat: no-repeat;
//   background-size: cover;
//   display: flex;
//   align-content: center;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 20px;
//   color: ${colors.paleGrey};
//   cursor: pointer;
// `;

const ButtonColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  button {
    width: 150px;
    margin-bottom: 8px;
    background: ${colors.paleGrey};
  }
`;

const ContainerLayout = styled.div`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  margin: -25px 0px 0px 0px;
  background: ${colors.lighterGrey};
`;

const LeftContent = styled.div`
  @media ${maxDevice.laptopL} {
    max-width: 50%;
  }
  @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
    min-width: auto;
  }
  @media ${maxDevice.sideNav} {
    padding: 20px 20px 100px 20px;
    overflow-x: hidden;
    min-height: 500px;
  }
  flex: 1 1 auto;
  min-height: 800px;
  max-width: 50%;
  min-width: 50%;
  background: white;
  padding: 30px 40px 100px 40px;
`;

const FormContainer = styled.div`
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const FormHeader = styled.h3`
  font-weight: ${fonts.semiBold};
  margin-bottom: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  span {
    flex: 1 1 auto;
  }
  p {
    flex: 0 0 auto;
    font-size: 11px;
    color: ${colors.paleGrey};
    cursor: pointer;
  }
`;

const RightContent = styled.div`
  @media ${maxDevice.laptop} {
    max-width: 50%;
  }
  @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
    ${FormContainer} {
      border: none;
      padding-bottom: 0;
    }
  }
  @media ${maxDevice.sideNav} {
    padding: 20px;
    ${FormContainer} {
      border: none;
      padding-bottom: 0;
    }
  }
  flex: 1 1 auto;
  max-width: 50%;
  padding: 30px 40px 20px 40px;
  /* position: relative; */
`;

UserPreferencesContainer.propTypes = {
  isConsumer: PropTypes.bool,
};

export default UserPreferencesContainer;
