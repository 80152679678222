import * as icons from '../assets';
import { DISCOVERY_TUTORIAL as tutorialLink } from './constants';
import { colors, assetMapColors } from '../styles/variables';

//LIST OF INTEGRATIONS WITH ALL INFO NEEDED FOR APPLICATION
export const integrationOptions = [
  {
    name: 'Advisor Innovation Labs',
    id: 'ailabs',
    image: icons.aiLabs,
    info: `Integrated digital solutions for Institutional Financial Services Firms, Bank Wealth Management Organizations, Independent Advisor Networks, and Broker-Dealers.`,
    url: 'https://advisorinnovationlabs.com/',
    inbound: true,
    has_sso: false,
  },
  {
    name: 'Salesforce',
    color: '#1798c1',
    id: 'axasf',
    image: icons.axasf,
    info: `Salesforce Relationship Groups, Clients, and Contract/Accounts can easily be aligned with existing or new Households in Asset-Map.`,
    info_url:
      'http://usersupport.asset-map.com/en/articles/4423642-salesforce-integration-equitable',
    inbound: true,
    has_sso: false,
    exclude_household: true,
    disable_deactivate: true,
  },
  {
    name: 'Addepar',
    id: 'addepar',
    image: icons.addepar,
    icon: icons.addeparIcon,
    info: 'Addepar is a global technology and data company that helps investment professionals provide the most informed, precise guidance for their clients. Addepar’s platform aggregates portfolio, market, and client data for over $5 trillion in assets.',
    small_text:
      'By clicking activate and completing the authentication process, you are are consenting to link your Asset-Map and Addepar accounts, and to the exchange of the following data types:',
    access: 'Contacts, Financials',
    color: '#000000',
    has_sso: false,
    search_placeholder: 'Search by Addepar Client Name',
  },
  {
    name: 'Black Diamond',
    id: 'blackdiamond',
    image: icons.blackDiamond,
    icon: icons.blackDiamondIcon,
    height: '35px',
    padding: '7.5px 0',
    info: `The Black Diamond Platform is an award-winning portfolio management system and client reporting solution for financial advisors and wealth managers.`,
    access: 'Contacts, Members, Accounts',
    color: '#1285C3',
    fields: [{ households: 1 }, { members: 4 }, { instruments: 15 }],
  },
  {
    name: 'Box',
    id: 'box',
    image: icons.box,
    icon: icons.boxIcon,
    info: `Box makes it simple to collaborate across your teams, customers, partners, and vendors — and access your files from any device, at any time. All that, and so much more.`,
    activeInfo: true,
    access: 'File Access',
    color: '#0061D5',
    exclude_household: true,
    file_storage: true,
  },
  {
    name: 'Dropbox',
    id: 'dropbox',
    image: icons.dropbox,
    icon: icons.dropboxIcon,
    info: `Bring traditional files, cloud content, Dropbox Paper docs and web shortcuts together in one place - and work the way that works for you.`,
    activeInfo: true,
    access: 'File Access',
    color: '#0060FF',
    exclude_household: true,
    file_storage: true,
  },
  {
    name: 'Ebix',
    id: 'ebix',
    image: icons.ebix,
    info: `Ebix Inc is a leading supplier of On-Demand software solutions & E-commerce services to the insurance industry in Offshore & Custom software development.`,
    url: 'https://www.ebix.com/',
    inbound: true,
    has_sso: false,
  },
  {
    name: 'Google Drive',
    id: 'googledrive',
    image: icons.googleDrive,
    icon: icons.googleDriveIcon,
    info: `Google's powerful search capabilities are embedded in Drive and offer unmatched speed, performance, and reliability. And features like Priority use AI to predict what you're looking for and surface the most relevant content—helping your team find files up to 50% faster.`,
    activeInfo: true,
    access: 'File Access',
    color: '#fccd46',
    exclude_household: true,
    file_storage: true,
  },
  {
    name: 'Holistiplan',
    id: 'holistiplan',
    image: icons.holistiplan,
    icon: icons.holistiplanIcon,
    info: 'Holistiplan revolutionizes tax planning by automating tax return review and highlighting opportunities for financial planners and investment advisors.',
    access: 'Clients',
    color: '#3ABB97',
    has_sso: false,
    search_placeholder: 'Search by Holistiplan Client Name',
    tile_maxWidth: '270px',
    maxWidth: '260px',
    maxHeight: '40px',
  },
  {
    name: 'Money Guide Pro',
    id: 'mgp',
    image: icons.moneyGuidePro,
    height: '25px',
    padding: '10px 0',
    info: `MoneyGuidePro© provides sophisticated solutions and smart assumptions to help advisors navigate the complex financial elements of their lives.`,
    url: 'https://www.moneyguidepro.com/ifa/',
    inbound: true,
    has_sso: false,
  },
  {
    name: 'Morningstar',
    id: 'morningstar',
    image: icons.morningstar,
    icon: icons.morningstarIcon,
    info: "Morningstar Office is Morningstar's Web-based solution for financial advisors.  It brings together the best of our capabilities in investment research, portfolio accounting, and client communications.",
    small_text:
      'By clicking activate and completing the authentication process you are consenting to link your Asset-Map and Morningstar Office accounts, and to the exchange of the following data types:',
    access: 'Members, Financials',
    color: '#f41a0e',
    has_sso: false,
    search_placeholder: 'Search by Morningstar Client Name',
    tile_maxWidth: '270px',
    maxWidth: '260px',
    maxHeight: '40px',
  },
  {
    name: 'Morningstar AWS',
    id: 'msaws',
    image: icons.morningstarAdvisorWorkstation,
    icon: icons.mawIcon,
    legend: icons.msawsLegend,
    info: 'Advisor Workstation streamlines investment research due diligence, portfolio analytics and construction, risk tolerance discovery, asset allocation decisions, and proposal-making to support advisory best practices.',
    warning:
      'You must be logged into your Morningstar Advisor Workstation account in another browser tab before clicking activate.',
    small_text:
      'By clicking activate and completing the authentication process, you are consenting to link your Asset-Map and Morningstar Advisor Workstation accounts, and to the exchange of the following data types:',
    access: 'Clients, Portfolios',
    color: '#f41a0e',
    has_sso: false,
    search_placeholder: 'Search by Morningstar Advisor Workstation Client Name',
    tile_maxWidth: '350px',
    maxWidth: '300px',
    maxHeight: '49px',
    paddingTop: '9px',
  },
  {
    name: 'Nitrogen',
    id: 'riskalyze',
    image: icons.nitrogen,
    icon: icons.nitrogenIcon,
    info: `Nitrogen enables advisors to pinpoint an investor's Risk Number and build a portfolio that contains just the right amount of risk.`,
    access: 'Contacts, Members, Financials',
    color: '#6941C7',
    fields: [{ households: 4 }, { members: 16 }, { instruments: 1 }],
  },
  {
    name: 'Orion',
    id: 'orion',
    image: icons.orion,
    icon: icons.orionIcon,
    info: `Built by advisors, for advisors, Orion's portfolio management software gets advisors back to the business of serving their clients.`,
    access: 'Contacts, Members, Financials',
    color: '#00AEEF',
    fields: [{ households: 2 }, { members: 4 }, { instruments: 3 }],
  },
  {
    name: 'Plannr',
    id: 'plannr',
    image: icons.plannr,
    icon: icons.plannrIcon,
    color: '#0E0E56',
    info: `Plannr is a collaboration of smart advisers and bright developers, building what the market needs; a product for the profession, built by the profession.`,
    access: 'Individuals, Plans',
    search_placeholder: 'Search by Plannr Individuals',
  },
  {
    name: 'Precise FP',
    id: 'precisefp',
    image: icons.preciseFP,
    info: `Complete your client’s full financial profile. View their complete account activity log to ensure you know your customer. Expand to even more integrations and build your own workflow featuring seamless document uploads with PreciseFP.`,
    inbound: true,
    url: 'https://precisefp.com/',
  },
  {
    name: 'Redtail',
    id: 'redtail',
    image: icons.redtail,
    icon: icons.redtailIcon,
    info: `Web-based Client Relationship Management software (CRM), integrates widely and deeply with other popular tools for the Financial Advisor.`,
    access: 'Contacts, Members, Financials',
    color: '#B3282D',
    fields: [{ households: 1 }, { members: 2 }],
  },
  {
    name: 'Schwab',
    id: 'schwab',
    image: icons.schwab,
    icon: icons.schwabIcon,
    info: `Schwab Advisor Center® has digital tools and resources to help you onboard new relationships, support your clients, and scale your business.`,
    small_text: `By clicking activate and completing the authentication process you are consenting to link your Asset-Map and Partner accounts, and to the exchange of the following data types:`,
    access: 'Contacts, Members, Financials',
    color: '#2fa1e0',
    has_sso: false,
    search_placeholder: 'Search by Schwab Client Name',
  },
  {
    name: 'Veo',
    id: 'td',
    image: icons.tdVeo,
    icon: icons.tdVeoIcon,
    info: `Manage your business the way you want with Veo One. From account management to trading and everything in between, it can help make your job a lot easier.`,
    access: 'Contacts, Members, Financials',
    color: '#4DAF46',
    fields: [{ households: 1 }, { members: 3 }, { instruments: 4 }],
    login_details: `<a href="https://usersupport.asset-map.com/en/articles/3362822-td-veo-integration" target="_blank"  rel="noopener noreferrer">Click here</a> for the required prerequisites needed to activate your Veo integration with Asset-Map`,
  },
  {
    name: 'Wealthbox',
    id: 'wealthbox',
    image: icons.wealthbox,
    icon: icons.wealthboxIcon,
    info: `Wealthbox® is a web-and-mobile CRM application for financial professionals. Integrated with leading broker-dealers and wealth-tech partners, Wealthbox is known for its modern product design that results in a powerful yet simple user-experience for RIA firms of all sizes.`,
    access: 'Contacts',
    color: '#106ed4',
  },
];

//LIST OF PARTNERS WITH ALL INFO NEEDED FOR PAGE DISPLAY
export const integrationPartners = [
  {
    name: 'Trust & Will',
    id: 'trust_and_will',
    order: 1,
    image: icons.trustAndWill,
    height: '50px',
    isPartner: true,
    info: `Trust & Will empowers financial professionals to meet their clients estate planning needs through our modern and affordable advisor dashboard solution.`,
    url: 'https://trustandwill.com/advisors/assetmap',
    color: '#708DC5',
  },
  {
    name: 'CPA.com',
    id: 'cpa.com',
    order: 2,
    image: icons.cpa,
    isPartner: true,
    isNew: true,
    info: `CPA.com brings innovative solutions to the accounting profession, either in partnership with leading providers or directly through its own development. The company has established itself as a thought leader on emerging technologies and as the trusted business advisor to practitioners in the U.S., with a growing global focus. CPA.com is a subsidiary of the American Institute of CPAs (AICPA) and part of the Association of International Certified Professional Accountants (AICPA & CIMA), the most influential body of accounting and financial professionals worldwide.`,
    url: 'https://www.cpa.com/',
    color: '#72246C',
  },
  {
    name: 'Snappy Kraken',
    id: 'snappy_kraken',
    order: 3,
    image: icons.snappyKraken,
    height: '55px',
    isPartner: true,
    info: `Snappy Kraken is an automated growth program that combines technology, content, and education to help financial advisers expand market share while simultaneously delivering higher quality experiences for existing clients.`,
    url: 'https://snappykraken.com/',
    color: '#ef3b39',
  },
  {
    name: 'Females and Finance',
    id: 'females_and_finance',
    order: 4,
    image: icons.faf,
    height: '55px',
    isPartner: true,
    info: `Females and Finance helps women and diverse talent succeed in financial services. We provide valuable networking opportunities with other like-minded professionals across companies, states, and financial sectors. In addition, we provide access to training and education on a variety of topics, as well as exposure to vetted financial organizations who are dedicated to moving the needle on feminine and diverse talent and their success, too.`,
    url: 'https://femalesandfinance.com/',
    color: '#9B4545',
  },
  {
    name: 'SEI',
    id: 'sei',
    order: 5,
    image: icons.sei,
    height: '35px',
    isPartner: true,
    info: `SEI (NASDAQ:SEIC) remains a leading global provider of investment processing, investment management, and investment operations solutions that help corporations, financial institutions, financial advisors, and ultra-high-net-worth families create and manage wealth`,
    url: 'https://seic.com/',
    color: '#000000',
  },
  {
    name: 'Financial Planning Association',
    id: 'fpa',
    order: 6,
    image: icons.fpa,
    height: '65px',
    isPartner: true,
    info: `The Financial Planning Association, is a leadership and advocacy organization based in Denver, Colorado. FPA works with academic leaders, legislative and regulatory bodies, financial services firms and consumer interest organizations.`,
    url: 'https://www.plannersearch.org/',
    //  color: '#ff7b3a',
    color: '#101947',
  },
  {
    name: 'FA Match',
    id: 'fa_match',
    order: 7,
    image: icons.faMatch,
    height: '35px',
    isPartner: true,
    info: `FA Match is the industry's only two-way, data-driven solution for advisor recruiting. Their proprietary technology helps advisors and firms focus on the criteria that matter most to their growth and personal goals - dead-end searches stop here. We have 20+ years of experience connecting high-performing advisors with the best firms out there.`,
    url: 'https://www.famatch.com',
    color: '#003462',
  },
  {
    name: 'Conneqtor',
    id: 'Conneqtor',
    order: 8,
    image: icons.conneqtor,
    height: '50px',
    isPartner: true,
    info: `Conneqtor is a financial advisor course that provides the training & master blueprint to start, build, and grow a business in months, not years. Through a coaching rich environment, Conneqtor's financial advisor training teaches advisors everything, step-by-step through a combination of on-demand online learning, tactical how-to's, plug-n-play templates, and global advisor community. This is the financial advisor course you have been looking for.`,
    url: 'https://www.conneqtor.co/',
    color: '#4FD0E3',
    imageHeight: 'auto',
  },
  {
    name: 'AAAA',
    id: 'aaaa',
    order: 9,
    image: icons.aaaa,
    isPartner: true,
    height: '35px',
    info: `AAAA is a non-profit membership organization for African American financial advisors and affiliated professionals. They work in alliance with academic leaders at HBCUs that support financial planning degree programs, legislative and regulatory bodies, financial services firms and consumer interest organizations.`,
    url: 'https://www.aaafainc.com/',
    color: '#BE1323',
  },
  {
    name: 'RIA in a Box®',
    id: 'ria',
    order: 10,
    image: icons.ria,
    isPartner: true,
    info: `RIA in a Box® is a leading registered investment adviser (RIA) compliance software as a service (SaaS) technology provider offering RIA compliance services.`,
    url: 'https://www.riainabox.com/',
    color: '#79281F',
  },
  {
    name: 'Female Advisor Network',
    id: 'fan',
    order: 11,
    image: icons.fan,
    isPartner: true,
    height: '65px',
    info: `The Female Advisor Network is a national membership organization for female financial advisors.  Their mission is to empower all female financial advisors by providing a community of support, education, mentorship, and collaboration.`,
    url: 'https://femaleadvisornetwork.org/',
    color: '#79ADD3',
  },
  {
    name: 'Schwab Intelligent Technologies® (SIT)',
    id: 'sit',
    order: 12,
    image: icons.sit,
    isPartner: true,
    info: `Schwab Intelligent Technologies® (SIT) and Schwab Performance Technologies® (SPT) provide technology solutions to independent investment advisors.`,
    url: 'https://advisorservices.schwab.com/managing-your-business/tech-integration',
    // color: '#00A0DF',
    //  color: '#A2AD00',
    color: '#005CB3',
  },
  {
    name: 'Finseca',
    id: 'finseca',
    order: 13,
    image: icons.finseca,
    isPartner: true,
    info: `Finseca advocates for the financial security profession, develops and grow its leaders, and promotes the noble and necessary work its members do to provide financial and retirement security for the individuals, families, and businesses they serve.`,
    url: 'https://www.finseca.org/',
    color: '#41008C',
  },
];

//LIST OF VALUES USED FOR HOUSEHOLD SIDE NAV MENU
export const sideNavItems = [
  {
    label: 'Actions',
    icon: icons.plusLogo,
    hasChildren: true,
    key: 'actions',
    order: 1,
    showConsumer: false,
  },
  {
    label: 'Household',
    icon: icons.households,
    hasChildren: false,
    to: 'summary',
    key: 'household',
    order: 2,
    showConsumer: true,
    alwaysShow: true,
  },
  {
    label: 'Asset-Maps',
    superscript: '®',
    icon: icons.navAssetMaps,
    hasChildren: false,
    to: 'assetmap',
    key: 'asset-map',
    order: 3,
    showConsumer: true,
    alwaysShow: true,
  },
  {
    label: 'Relationships',
    icon: icons.members,
    hasChildren: false,
    to: 'relationships',
    key: 'members',
    order: 4,
    showConsumer: true,
  },
  {
    label: 'Financials',
    icon: icons.financials,
    hasChildren: false,
    to: 'financials',
    key: 'financials',
    order: 5,
    showConsumer: true,
  },
  {
    label: 'Signals',
    superscript: '™',
    icon: icons.trafficLight,
    hasChildren: false,
    to: 'signals',
    key: 'signals',
    order: 6,
    showConsumer: true,
    hidden: true,
  },
  {
    label: 'Target-Maps',
    superscript: '®',
    icon: icons.targetMaps,
    hasChildren: false,
    to: 'targetmap',
    key: 'targetmaps',
    order: 7,
  },
  {
    label: 'Notes',
    icon: icons.notes,
    hasChildren: false,
    to: 'notes',
    key: 'notes',
    order: 8,
  },
  {
    label: 'Balance Sheet',
    icon: icons.balance,
    hasChildren: false,
    to: 'balance_sheet',
    key: 'balance_sheet',
    order: 9,
    showConsumer: true,
  },
  {
    label: 'Policy Summary',
    icon: icons.policy,
    hasChildren: false,
    to: 'policy_summary',
    key: 'policy_summary',
    order: 10,
    showConsumer: true,
  },
  {
    label: 'Beneficiaries',
    icon: icons.beneficiaries,
    hasChildren: false,
    to: 'beneficiaries',
    key: 'beneficiaries',
    order: 11,
    showConsumer: true,
  },
  {
    label: 'Reports',
    icon: icons.reports,
    hasChildren: false,
    to: 'reports',
    key: 'reports',
    order: 12,
    showConsumer: true,
  },
  {
    label: 'KFFs',
    icon: icons.kffs,
    hasChildren: false,
    to: 'kffs',
    key: 'kffs',
    order: 13,
  },
  {
    label: 'Questionnaires',
    icon: icons.clipboardList,
    hasChildren: false,
    to: 'questionnaires',
    key: 'questionnaires',
    order: 14,
    hidden: true,
  },
  // {
  //   label: 'Analytics',
  //   icon: icons.analytics,
  //   hasChildren: false,
  //   to: 'analytics',
  // },
  // {
  //   label: 'Next Product',
  //   icon: icons.nextProduct,
  //   hasChildren: false,
  //   lastItem: true,
  //   to: 'next-product',
  // },
];

//SEPARATE SIGNALS ITEM - TO BE MERGED
export const signalsNavItem = {
  label: 'Signals',
  superscript: '™',
  icon: icons.trafficLight,
  hasChildren: false,
  to: 'signals',
  key: 'signals',
  order: 6,
};

//SEPARATE SIGNALS ITEM - TO BE MERGED
export const surveysNavItem = {
  label: 'Questionnaires',
  icon: icons.clipboardList,
  hasChildren: false,
  to: 'questionnaires',
  key: 'questionnaires',
  order: 14,
};

// OLD OPTION TO HIDE DISCLAIMER
// const sideNavBottomLink = {
//   label: 'Disclaimer',
//   icon: asterisk,
//   hasChildren: false,
//   isBottom: true,
//   to: 'disclaimer',
// };

//DEFAULT LIST OF MODAL TABS FOR INSTRUMENTS AND MEMBERS
export const modalTabs = [
  { label: 'Essentials', value: 'essentials' },
  { label: 'Details', value: 'details' },
  { label: 'Notes', value: 'notes' },
];

export const clientModalTabs = [
  { label: 'Essentials', value: 'essentials' },
  { label: 'Details', value: 'details' },
];

//LIST OF ENTITY OWNERSHIP OPTIONS FOR AMR PDF
export const amrEntityOwnershipPreferences = [
  { value: 'off', label: 'Off' },
  { value: 'expanded', label: 'Expanded' },
  { value: 'collapsed', label: 'Collapsed' },
];

//LIST OF ORIENTATION OPTIONS FOR AMR PDF
export const amrOrientationPreferences = [
  { value: 'default', label: 'Best Fit' },
  { value: 'landscape', label: 'Landscape' },
  { value: 'portrait', label: 'Portrait' },
];

//DEFAULT LAYOUT FOR GENERATE REPORTS
export const defaultReportLayout = {
  id: '0',
  value: 'amr',
  is_default: false,
  is_system: true,
  name: 'Asset-Map Default',
  label: 'Asset-Map Default',
  data: [
    {
      value: 'cover',
      priority: 1,
      is_checked: true,
    },
    {
      value: 'assetmap',
      priority: 2,
      is_checked: true,
    },
    {
      value: 'signals',
      priority: 3,
      is_checked: false,
    },
    {
      value: 'targetmap',
      priority: 4,
      is_checked: true,
    },
    {
      value: 'notes',
      priority: 5,
      is_checked: false,
    },
    {
      value: 'balancesheet',
      priority: 6,
      is_checked: false,
    },
    {
      value: 'policysummary',
      priority: 7,
      is_checked: false,
    },
    {
      value: 'beneficiary',
      priority: 8,
      is_checked: false,
    },
    {
      value: 'members',
      priority: 9,
      is_checked: false,
    },
    {
      value: 'financials',
      priority: 10,
      is_checked: false,
    },
    {
      value: 'factfinder',
      priority: 11,
      is_checked: false,
    },
    {
      value: 'financialdrill',
      priority: 12,
      is_checked: false,
    },
    {
      value: 'milestones',
      priority: 13,
      is_checked: false,
    },
    {
      value: 'custom01',
      priority: 14,
      is_checked: false,
    },
    {
      value: 'custom02',
      priority: 15,
      is_checked: false,
    },
    {
      value: 'custom03',
      priority: 16,
      is_checked: false,
    },
    {
      value: 'custom04',
      priority: 17,
      is_checked: false,
    },
    {
      value: 'custom05',
      priority: 18,
      is_checked: false,
    },
    {
      value: 'custom06',
      priority: 19,
      is_checked: false,
    },
    {
      value: 'custom07',
      priority: 20,
      is_checked: false,
    },
    {
      value: 'custom08',
      priority: 21,
      is_checked: false,
    },
    {
      value: 'custom09',
      priority: 22,
      is_checked: false,
    },
    {
      value: 'custom10',
      priority: 23,
      is_checked: false,
    },
    {
      value: 'disclosure',
      priority: 24,
      is_checked: true,
    },
  ],
};

//DEFAULT ORDER AND VALUES FOR REPORTS LIST
export const reportsList = [
  {
    value: 'cover',
    name: 'Cover Page',
    icon: icons.signed,
    pages: 1,
    hasOptions: false,
    selected: true,
    expanded: false,
    order: 1,
    allSelected: false,
    disabled: false,
    available: true,
  },
  {
    value: 'assetmap',
    name: 'Asset-Map',
    childValue: 'assetmaps',
    icon: icons.plusLogoOutline,
    pages: 0,
    hasOptions: true,
    selected: true,
    expanded: true,
    order: 2,
    disabled: false,
    allSelected: false,
    available: true,
    showEntityOwnership: true,
    showOrientation: true,
    showIncludeKFFs: true,
    showIncludeLegalCount: true,
    includeLegalCount: false,
    includeKffs: false,
    options: {
      entityOwnershipOptions: amrEntityOwnershipPreferences,
      amrOrientationOptions: amrOrientationPreferences,
    },
    children: [
      {
        label: 'Default',
        value: 'default',
        selected: true,
        order: 1,
        pages: 0,
      },
    ],
  },
  {
    value: 'signals',
    name: 'Signals',
    icon: icons.trafficLight,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 3,
    allSelected: false,
    disabled: false,
    available: true,
  },
  {
    value: 'targetmap',
    name: 'Target-Maps',
    childValue: 'targetmaps',
    icon: icons.targetMaps,
    pages: 0,
    hasOptions: true,
    selected: true,
    expanded: true,
    order: 4,
    disabled: false,
    allSelected: true,
    cashFlowAllSelected: false,
    available: true,
  },
  {
    value: 'notes',
    name: `Notes`,
    icon: icons.notes,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 5,
    allSelected: false,
    disabled: false,
    available: true,
  },
  {
    value: 'balancesheet',
    name: 'Balance Sheet',
    childValue: 'balancesheet_members',
    icon: icons.balance,
    pages: 1,
    hasOptions: true,
    selected: false,
    expanded: false,
    allSelected: true,
    order: 6,
    disabled: false,
    available: true,
  },
  {
    value: 'policysummary',
    name: 'Policy Summary',
    childValue: 'policysummary_members',
    icon: icons.policy,
    pages: 1,
    hasOptions: true,
    selected: false,
    expanded: false,
    allSelected: true,
    allTypesSelected: true,
    order: 7,
    disabled: false,
    available: true,
    types: [
      {
        label: 'Life Insurance',
        value: 'life',
        selected: true,
        pages: 0,
      },
      {
        label: 'Disability Insurance',
        value: 'disability',
        selected: true,
        pages: 0,
      },
      {
        label: 'Long-term Care Insurance',
        value: 'ltc',
        selected: true,
        pages: 0,
      },
      {
        label: 'Property And Casualty',
        value: 'propcas',
        selected: true,
        pages: 0,
      },
      {
        label: 'Medical',
        value: 'medical',
        selected: true,
        pages: 0,
      },
      {
        label: 'Other',
        value: 'otherinsurance',
        selected: true,
        pages: 0,
      },
    ],
  },
  {
    value: 'beneficiary',
    name: 'Beneficiary Report',
    childValue: 'beneficiary_members',
    icon: icons.users,
    pages: 1,
    hasOptions: true,
    selected: false,
    expanded: false,
    order: 8,
    allSelected: true,
    disabled: false,
    available: true,
  },
  {
    value: 'members',
    name: 'Relationship Inventory',
    icon: icons.members,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 9,
    allSelected: false,
    disabled: false,
    available: true,
  },
  {
    value: 'financials',
    name: 'Financial Inventory',
    icon: icons.financials,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 10,
    allSelected: false,
    disabled: false,
    available: true,
  },
  {
    value: 'factfinder',
    name: 'Fact Finder Summary',
    icon: icons.glasses,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 11,
    allSelected: false,
    disabled: false,
    available: true,
  },
  {
    value: 'financialdrill',
    name: `Financial Fire Drills (6L's)`,
    icon: icons.fire,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 12,
    allSelected: false,
    disabled: false,
    available: true,
  },
  {
    value: 'milestones',
    name: `Milestones`,
    icon: icons.milestone,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 13,
    allSelected: false,
    disabled: false,
    available: true,
  },
  {
    value: 'custom01',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 14,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'custom02',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 15,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'custom03',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 16,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'custom04',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 17,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'custom05',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 18,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'custom06',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 19,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'custom07',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 20,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'custom08',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 21,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'custom09',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 22,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'custom10',
    custom: true,
    icon: icons.customReport,
    pages: 1,
    hasOptions: false,
    selected: false,
    expanded: false,
    order: 23,
    allSelected: false,
    disabled: false,
    available: false,
  },
  {
    value: 'disclosure',
    name: 'Disclosure',
    icon: icons.asterisk,
    pages: 1,
    hasOptions: false,
    selected: true,
    expanded: false,
    order: 24,
    allSelected: false,
    disabled: false,
    available: true,
  },
];

//ALL THE HEADER CONTENT USED FOR THE PAGE HEADER COMPONENT
export const headerContent = {
  discovery: {
    heading: 'Discovery',
    subcontent: [
      'Save time building an Asset-Map by letting your client or prospect submit their financial information directly to your account.',
      'The discovery process will help to gather important financial information that can be viewed in a report or used to build a new Household in Asset-Map.',
    ],
    superscript: '™',
    showButton: true,
    buttonLink: tutorialLink,
    buttonText: 'Tutorial',
    isExternal: true,
  },
  householdManual: {
    heading: 'Add a New Household',
    subcontent: [
      'Start by selecting a household type, entering a name for your household and add the location details.',
      'Then add the details for your primary member and click add member. You can add additional members and entities before creating a household.',
    ],
  },
  userPreferences: {
    heading: 'User Preferences',
    subcontent: [
      'Change your profile picture, contact information, password, application settings and notifications below.',
    ],
  },
  memberDeletedHouseholds: {
    heading: 'Manage Deleted Households',
    subcontent: ['Restore recently deleted households (within last 30 days).'],
  },
  consumerPreferences: {
    heading: 'User Preferences',
    subcontent: [
      'Change your profile picture, contact information and password below',
    ],
  },
  teamSettings: {
    heading: 'Team Settings',
    subcontent: [
      'Change your organization logo, account settings, asset-map report settings, disclosures and disclaimers below.',
      'Click on the tabs to view discovery, membership and activity.',
    ],
    showButton: true,
    buttonText: 'Invite Members',
  },
  managerDeletedHouseholds: {
    heading: 'Manage Deleted Households',
    subcontent: ['Restore recently deleted households (within last 30 days).'],
  },
  integrations: {
    heading: 'Integration Manager',
    subcontent: [
      `Manage all the integrations you've connected through your advisor preferences.`,
      'Activate or deactivate individual integrations for members, households, and/or instruments.',
    ],
  },
  partners: {
    heading: 'Partners',
    subcontent: [
      'Partners are important, so we’d like to introduce you to a few of ours!  Use this page to explore our corporate and non-profit partners, learn a bit more about who they are, what they offer, and how they might be able to add value to your business today.',
    ],
  },
  integrationsHousehold: {
    heading: 'Create Household from Integrations',
    subcontent: [
      `Search for users, assets and households using any integration to create a household.`,
      'You must activate integrations before you can create households.',
    ],
    isExternal: false,
    showButton: true,
    buttonLink: '/integration',
    buttonText: 'Manage Integrations',
  },
  integrationsSubHeader: {
    heading: 'Asset-Map Integrations',
    subcontent: [
      `Choose one or more of our available partners that best suit your needs to effectively enhance the Asset-Map experience for your clients and yourself alike.`,
    ],
  },
  managerIntegrationsSubHeader: {
    heading: 'Integrations',
    subcontent: [`Select which integrations should be available to all users.`],
  },
  notifications: {
    heading: 'Notifications',
    subcontent: [
      'Here is a list of all your notifications, you can mark them as read or unread.',
    ],
    showButton: true,
    buttonText: 'Mark All As Read',
  },
  householdInfo: {
    heading: 'Household Information',
    subcontent: [
      'The household name is typically the Last Name of the Primary household member.',
      'Enter the contact information as well as select if this household is a client, prospective client, or sample.',
    ],
  },
  investorProfile: {
    heading: 'Investor Profile',
    subcontent: [
      `The Investor Profile allows you to easily track and update your client's current investment experience, financial priorities, and risk tolerance.`,
    ],
  },
  householdSharing: {
    heading: 'Client Portal',
    subcontent: [
      'Share access to this household with the members of this household.',
      'Click the share icon next to the member to get started.',
    ],
  },
  householdDeletedItems: {
    heading: 'Manage Deleted Household Items',
    subcontent: [
      'Restore recently deleted household members and financials (within last 30 days).',
    ],
  },
  householdLoader: {
    heading: 'Data Loader',
    subcontent: [
      'Use this form to load or sync data from other sources by using a CSV file.',
    ],
  },
  members: {
    heading: 'Relationships',
    showButton: true,
    isPDF: true,
    noBottomBorder: true,
    subcontent: [
      `This list view can be used to review and expand awareness of the people and entities that surround, impact, or influence the client's financial decision-making.`,
    ],
  },
  family: {
    heading: 'Relationships',
    subcontent: [
      `This interactive map view can be used to visualize the people and entities that surround, impact, or influence the client's financial decision-making. <a target="__blank" href="https://forms.clickup.com/37445812/f/13pr5m-26213/ZFQQ41O9KMSONR2XM3?Topic%20of%20feedback=relationshipmap">Share your feedback</a>.`,
    ],
    showButton: true,
    isPDF: true,
    noBottomBorder: true,
  },
  financials: {
    heading: 'Financials',
    showButton: true,
    isPDF: true,
    noBottomBorder: true,
  },
  targetMaps: {
    heading: 'Target-Maps',
    superscript: '®',
    buttonText: 'New Target-Map',
    noBottomBorder: true,
  },
  notes: {
    heading: 'Notes',
    subcontent: [
      `Here is a list of the notes for this household's members, entities, financials and target-maps.`,
      'Click on the name or note value to update your notes.',
    ],
    showButton: true,
    isPDF: true,
  },
  balanceSheet: {
    heading: 'Balance Sheet',
    subcontent: [
      'Select member(s) to see and overview of matching financials.',
    ],
    showButton: true,
    isPDF: true,
  },
  policy: {
    heading: 'Policy Summary',
    subcontent: ['Select member(s) to view corresponding instruments.'],
    showButton: true,
    isPDF: true,
  },
  kffs: {
    heading: 'Key Financial Figures',
    subcontent: [
      `Here you can see a summary of all the Key Financial Figures (KFFs) for this household.`,
    ],
  },
  atlas: {
    heading: 'Insights + Analytics',
    subcontent: [
      `Welcome to your Insights + Analytics Dashboard! You can leverage the filters below to gain valuable insights into user activity across your organization.`,
    ],
  },
  reports: {
    heading: 'Reports',
    subcontent: [
      'Select any or all of the items below to be included in your report, or chose from your saved reports.',
    ],
  },
  consumerReports: {
    heading: 'Shared Reports',
    subcontent: [
      'The following PDF reports have been shared with you by your advisor. Click download to view the PDF.',
    ],
  },
  beneficiaries: {
    heading: 'Beneficiaries',
    subcontent: [
      'Select a member or entity to view the identified beneficiaries for each of the corresponding financials and to discuss alignment with intentions.',
    ],
    showButton: true,
    isPDF: true,
  },
  analytics: {
    heading: 'Analytics',
    subcontent: ['Coming soon.'],
  },
  householdSignals: {
    heading: 'Signals',
    subcontent: [
      'Asset-Map Signals are an indicator for the financial preparedness of a household to fund a sustained loss associated with critical events that commonly disrupt financial security. The following indications are to be used to identify potential scenarios that require attention and is not intended to provide financial advice or recommendation of action. Each indication should be explored and confirmed by a professional to determine the unique circumstances of every household.',
    ],
    superscript: '™',
    showButton: true,
    isPDF: true,
  },
  surveys: {
    heading: 'Questionnaires',
    subcontent: [
      'Interested in a customized questionnaire? <a target="_blank" href="https://asset-map.com/questionnaires">Click Here.',
    ],
  },
  tmPref: {
    heading: 'Target-Map Preferences',
    subcontent: ['Select a tab to modify Target-Map Preferences.'],
  },
  kffPref: {
    heading: 'Key Financial Figures Settings',
    subcontent: [
      `Use the checkboxes below to select up to five Key Financial Figures (KFFs) that you would like to reveal in the KFF box on the Household's Summary page and/or Asset-Map Report.`,
    ],
    buttonText: 'Save Settings',
    showButton: true,
  },
  billing: {
    heading: 'Billing',
    subcontent: [
      'Update billing info, view invoices and see subscription status by clicking the tabs below.',
    ],
  },
  import: {
    heading: 'Import Household',
    subcontent: [
      'Create a household using existing data.',
      'Your data must be in CSV format in order to upload.',
    ],
  },
  milestones: {
    heading: 'Milestones',
    showButton: true,
    isPDF: true,
    noBottomBorder: true,
  },
};

//LIST OF SIGNALS OPTIONS USED FOR CUSTOM TEAM MANAGER SETTINGS
export const signalLabels = [
  {
    label: 'Liquidity',
    key: 'liquidity',
    details: `Preparedness to fund a short-term demand for cash equal to base number of months of replacement income.`,
    order: 1,
    min: 0,
    max: 1.5,
    green: 1,
    red: 0.5,
  },
  {
    label: 'Long-Term Disability',
    key: 'ltd',
    details: `Preparedness to fund a sustained long-term loss of earned income due to disability.`,
    order: 2,
    min: 0,
    max: 1.5,
    green: 0.7,
    red: 0.35,
  },
  {
    label: 'Loss of Life',
    key: 'life',
    details: `Preparedness to replace a permanent loss of earned income due to premature death.`,
    order: 3,
    min: 0,
    max: 1.5,
    green: 0.7,
    red: 0.35,
  },
  {
    label: 'Long-Term Care',
    key: 'ltc',
    details: `Preparedness to fund a long-term care event for the primary members.`,
    order: 4,
    min: 0,
    max: 1.5,
    green: 1,
    red: 0.5,
  },
  {
    label: 'Longevity',
    key: 'longevity',
    details: `Preparedness to fund long-term demand for sustainable income due to longevity.`,
    order: 5,
    min: 0,
    max: 1.5,
    green: 0.7,
    red: 0.35,
  },
  {
    label: 'Legal Liability',
    key: 'liability',
    details: `Preparedness to fund a legal liability attack on assets.`,
    order: 6,
    min: 0,
    max: 1.5,
    green: 0.7,
    red: 0.35,
  },
];

export const signalDetails = {
  liquidity: {
    name: 'Liquidity',
    details: `This Signal is based on the availability of assets to self-fund an unexpected expense equal to 3 months of total gross income. It calculates the effective emergency reserves of primary members' non-retirement assets relative to primary members' annual earned income.`,
    considerations: [
      {
        text: `Determine an ideal emergency reserve balance and location for these funds, discuss household expenses, and confirm a more accurate reserve level.`,
      },
      {
        text: `Consider other non-obvious resources that may be available in a liquidity crunch.`,
      },
      { text: `Identify a specific account as the liquidity reserve.` },
    ],
  },
  ltd: {
    name: 'Long-Term Disability',
    details: `This Signal is based on the ability to replace lost gross earned income at key ratio thresholds in the event of disability. It calculates the replacement ratio comparing the total primary members' household annual disability income insurance coverage relative to household annual earned income.`,
    additional: `Assets, Inflation and time value of money are not considered in this calculation.`,
    considerations: [
      {
        text: `Determine earned income dependency, discuss available employer benefits, and clarify core living expenses.`,
      },
      {
        text: `Examine existing or potential disability insurance and consider its definition, features and durations of coverage.`,
      },
      { text: `Run a Disability Target-Map`, targetMapType: 'disability' },
    ],
  },
  life: {
    name: 'Loss of Life',
    details: `This Signal is based on the ability to replace lost gross earned income at key ratio thresholds in the event of premature death. It calculates the replacement ratio of primary members' household life insurance coverage relative to their remaining aggregate, pre-retirement annual earned income.`,
    additional: `Assets, Inflation and time value of money are not considered in this calculation.`,
    considerations: [
      {
        text: `Determine earned income dependency for survivors, discuss available employer benefits, and clarify core living expenses.`,
      },
      {
        text: `Examine existing or potential life insurance and consider duration, owner, beneficiaries, and other potential dependencies on earned income potential.`,
      },
      { text: `Run a Loss-of-Life Target-Map`, targetMapType: 'death' },
    ],
  },
  ltc: {
    name: 'Long-Term Care',
    details: `This Signal is based on the ability to fund the total cost of care at key ratio thresholds. It calculates the funding ratio of primary members' existing household long-term care insurance coverage, plus their net worth (less home equity), relative to an average aggregate cost of care for each primary member(s).`,
    additional: `Inflation and time value of money are not considered in this calculation.`,
    considerations: [
      {
        text: `Determine reasonable cost of care locally, the desired outcome and any existing plans.`,
      },
      {
        text: `Examine existing or potential LTC insurance for policy benefit, duration, features, home health care options, and desire to retain an estate.`,
      },
      {
        text: `Run a Long-term Care Target-Map`,
        targetMapType: 'ltc',
      },
    ],
  },
  longevity: {
    name: 'Longevity',
    details: `This Signal is based on the ability to fund current income beyond average mortality ranges. It calculates the funding ratio by totaling the primary members' household expected unearned income in retirement, plus net worth less residence home equity relative to the remaining aggregate current income through age 95.`,
    additional: `Inflation and time value of money are not considered in this calculation.`,
    considerations: [
      {
        text: `Determine all sources of income available during retirement years, discuss pension election availability, and clarify core living expenses in retirement.`,
      },
      {
        text: `Examine existing or potential sources of income that are sustainable and consider payment options within government issued retirement programs.`,
      },
      { text: `Run a Retirement Target-Map`, targetMapType: 'retirement' },
    ],
  },
  liability: {
    name: 'Legal Liability',
    details: `This Signal is based on the ability to protect current attachable net worth from a legal attack on assets at key ratio thresholds. It calculates the exposure ratio by totaling the primary members' household liability coverage relative to net worth (excluding retirement assets).`,
    additional: `Inflation and time value of money are not considered in this calculation.`,
    considerations: [
      {
        text: `Determine if the household is in a high risk liability profession or other target, and discuss asset protection strategies applicable to the relevant jurisdiction.`,
      },
      {
        text: `Examine existing or potential sources of coverage and consider removing exposures.`,
      },
      {
        text: `Run a Custom Target-Map illustrating a liability event`,
        targetMapType: 'custom',
      },
    ],
  },
};

//HOUSEHOLD INDEX DEFAULT LAYOUT
export const systemDefaultHHILayout = {
  id: '0',
  name: 'Asset-Map Default',
  version: 1,
  is_default: true,
  is_system: true,
  data: {
    group: 'me',
    sort: ['name'],
    filter: {},
    columns: [
      'id',
      'linked_any',
      'name',
      'modified_user_utc',
      'householdmember_count',
      'instrument_count',
      'managed_count',
      'owner_name',
      'status',
      'targetmap_count',
      'wallet_share',
    ],
    visibleColumns: [
      'id',
      'linked_any',
      'name',
      'modified_user_utc',
      'householdmember_count',
      'instrument_count',
      'managed_count',
      'owner_name',
      'status',
      'targetmap_count',
      'wallet_share',
    ],
  },
};

//SIGNALS HHI  LAYOUT
export const signalsHHILayout = {
  id: '1',
  name: 'Signals',
  version: 1,
  is_default: false,
  is_system: true,
  data: {
    group: 'me',
    sort: ['name'],
    filter: {},
    columns: [
      'id',
      'name',
      'owner_name',
      'signal_ltc_state',
      'signal_ltd_state',
      'signal_liability_state',
      'signal_liquidity_state',
      'signal_longevity_state',
      'signal_life_state',
    ],
    visibleColumns: [
      'id',
      'name',
      'owner_name',
      'signal_ltc_state',
      'signal_ltd_state',
      'signal_liability_state',
      'signal_liquidity_state',
      'signal_longevity_state',
      'signal_life_state',
    ],
  },
};

//HOUSEHOLD INDEX HIDDEN ID COLUMN
export const idColumnHHI = {
  key: 'id',
  dataKey: 'id',
  title: 'id',
  width: 0,
  maxWidth: 0,
  sortable: false,
  style: { display: 'none' },
  align: 'right',
  display: false,
  fieldName: 'id',
  format: 'textFormatter',
  label: 'Id',
  priority: 0,
  resizable: false,
  short: 'id',
  sort: false,
  type: 'string',
  value: 'id',
  visible: false,
  hideDropdown: true,
};

// DEFAULT SELECTED COLUMNS FOR HOUSEHOLD INDEX
export const standardHHIColumns = [
  {
    key: 'id',
    dataKey: 'id',
    title: 'id',
    width: 0,
    maxWidth: 0,
    sortable: false,
    style: { display: 'none' },
  },
  {
    key: 'linked_any',
    dataKey: 'linked_any',
    title: 'Linked?',
    width: 90,
    minWidth: 50,
    sortable: true,
    resizable: true,
    style: {
      borderRight: `1px solid ${colors.lightGrey}`,
      justifyContent: 'center',
    },
  },
  {
    key: 'link',
    dataKey: 'link',
    title: 'Household',
    width: 550,
    minWidth: 150,
    sortable: true,
    resizable: true,
    style: {
      borderRight: `1px solid ${colors.lightGrey}`,
      paddingLeft: '15px',
    },
  },
  {
    key: 'owner',
    dataKey: 'owner_name',
    title: 'Lead Advisor',
    width: 250,
    minWidth: 120,
    sortable: true,
    resizable: true,
    style: {
      borderRight: `1px solid ${colors.lightGrey}`,
      justifyContent: 'center',
    },
  },
  {
    key: 'status',
    dataKey: 'status',
    title: 'Status',
    width: 180,
    minWidth: 80,
    sortable: true,
    resizable: true,
    style: {
      textTransform: 'capitalize',
      borderRight: `1px solid ${colors.lightGrey}`,
      justifyContent: 'center',
    },
  },
  {
    key: 'modified',
    dataKey: 'modifiedRel',
    title: 'Modified',
    width: 250,
    minWidth: 100,
    sortable: true,
    resizable: true,
    style: {
      borderRight: `1px solid ${colors.lightGrey}`,
      justifyContent: 'center',
    },
  },
  {
    key: 'accountsUM',
    dataKey: 'managed_count',
    title: 'Accts UM',
    width: 180,
    maxWidth: 200,
    minWidth: 50,
    sortable: true,
    resizable: true,
    style: {
      borderRight: `1px solid ${colors.lightGrey}`,
      justifyContent: 'flex-end',
    },
  },
  {
    key: 'walletShare',
    dataKey: 'ws',
    title: 'WS %',
    width: 200,
    minWidth: 50,
    sortable: true,
    resizable: true,
    style: {
      textAlign: 'center',
      borderRight: `1px solid ${colors.lightGrey}`,
      justifyContent: 'flex-end',
    },
  },
  {
    key: 'members',
    dataKey: 'householdmember_count',
    title: 'Members',
    width: 200,
    minWidth: 50,
    sortable: true,
    resizable: true,
    style: {
      borderRight: `1px solid ${colors.lightGrey}`,
      justifyContent: 'flex-end',
    },
  },
  {
    key: 'instruments',
    dataKey: 'instrument_count',
    title: 'Financials',
    width: 200,
    minWidth: 50,
    sortable: true,
    resizable: true,
    style: {
      borderRight: `1px solid ${colors.lightGrey}`,
      justifyContent: 'flex-end',
    },
  },
  {
    key: 'targetMaps',
    dataKey: 'targetmap_count',
    title: 'Target-Maps',
    width: 200,
    minWidth: 50,
    sortable: true,
    resizable: true,
    style: { justifyContent: 'flex-end' },
  },
];

//OPTIONS THAT ARE USED IN HOUSEHOLD INDEX FILTER TEXT DROPDOWNS
export const textFilterOptions = [
  {
    label: 'Contains',
    value: 'contains',
    not: false,
  },
  {
    label: 'Does Not Contain',
    value: 'does_not_contain',
    not: true,
  },
  {
    label: 'Is',
    value: 'is',
    not: false,
  },
  {
    label: 'Is Not',
    value: 'is_not',
    not: true,
  },
  {
    label: 'Starts With',
    value: 'startswith',
    not: false,
  },
  {
    label: 'Ends With',
    value: 'endswith',
    not: false,
  },
];

//OPTIONS THAT ARE USED IN HOUSEHOLD INDEX FILTER INTEGER DROPDOWNS
export const integerFilterOptions = [
  { label: '=', value: 'eq' },
  { label: '>', value: 'gt' },
  { label: '<', value: 'lt' },
  {
    label: '>=',
    value: 'gte',
  },
  {
    label: '<=',
    value: 'lte',
  },
];

//KFF OPTIONS, DISPLAYS AND CATEGORIES
export const kffPanelOptions = [
  {
    heading: 'Family Planning Ratios',
    value: 'family_planning',
    side: 'left',
    options: [
      // {
      //   label: 'Family Risk Preference',
      //   key: 'risk_preference',
      //   type: 'text',
      // },
      // {
      //   label: 'Investment Objective',
      //   key: 'investment_objective',
      //   type: 'text',
      // },
      { label: 'Debt/Equity Ratio', key: 'ratio_debt', type: 'percent' },
      { label: 'Assets', key: 'assets_amount', type: 'currency' },
      {
        label: 'Total Assets Under Management',
        key: 'managed_assets_amount',
        type: 'currency',
      },
      // {
      //   label: 'Dependent Assets',
      //   key: 'assets_dependent_amount',
      //   type: 'currency',
      // },
      { label: 'Liabilities', key: 'liabilities_amount', type: 'currency' },
      { label: 'Net Worth', key: 'net_worth', type: 'currency' },
      {
        label: 'Assets Real Estate Equity Amount',
        key: 'assets_residence_equity_amount',
        type: 'currency',
      },
      {
        label: '% of Assets in Non-Retirement Funds',
        key: 'ratio_cash_position',
        type: 'percent',
      },
      {
        label: '% of Assets in Retirement Plans',
        key: 'ratio_retirement_position',
        type: 'percent',
      },
      {
        label: 'Earned Household Income',
        key: 'income_earned_amount',
        type: 'currency',
      },
      { label: 'Wallet Share', key: 'wallet_share', type: 'percent' },
      { label: 'Household Income', key: 'incomes_amount', type: 'currency' },
      {
        label: 'Less cash-outflows',
        key: 'expenses_amount',
        type: 'currency',
      },
      {
        label: 'Net Household Income',
        key: 'net_incomes_amount',
        type: 'currency',
      },
    ],
  },
  {
    heading: 'Accounts',
    value: 'accounts',
    side: 'left',
    options: [
      {
        label: 'Accounts Under Management',
        key: 'managed_count',
        type: 'number',
      },
      // {
      //   label: 'Dependent Accounts Under Management',
      //   key: 'managed_assets_dependent_count',
      //   type: 'number',
      // },
      {
        label: 'Retirement Assets Under Management',
        key: 'managed_assets_retirement_count',
        type: 'number',
      },
      {
        label: 'Non-retirement Assets Under Management',
        key: 'managed_assets_nonretirement_count',
        type: 'number',
      },
    ],
  },
  {
    heading: 'Assets',
    value: 'assets',
    side: 'right',
    options: [
      {
        label: 'Cash Value (Life Insurance)',
        key: 'insurances_cash_value_amount',
        type: 'currency',
      },
      {
        label: 'Cash Value Under Management',
        key: 'managed_assets_cash_amount',
        type: 'currency',
      },
      {
        label: 'Annuity Accounts',
        key: 'assets_annuity_amount',
        type: 'currency',
      },
      {
        label: 'Annuity Accounts Under Management	',
        key: 'managed_assets_annuity_amount',
        type: 'currency',
      },
      {
        label: 'Non-retirement Assets',
        key: 'assets_nonretirement_amount',
        type: 'currency',
      },
      {
        label: 'Non-retirement Assets Under Management',
        key: 'managed_assets_nonretirement_amount',
        type: 'currency',
      },
      {
        label: 'Retirement Accounts',
        key: 'assets_retirement_amount',
        type: 'currency',
      },
      {
        label: 'Retirement Accounts Under Management',
        key: 'managed_assets_retirement_amount',
        type: 'currency',
      },
    ],
  },
  {
    heading: 'Risk Management',
    value: 'risk_management',
    side: 'right',
    options: [
      {
        label: 'Life Insurance Coverage',
        key: 'insurances_life_amount',
        type: 'currency',
      },
      {
        label: 'Term Coverage',
        key: 'insurances_term_amount',
        type: 'currency',
      },
      {
        label: 'Permanent Coverage',
        key: 'insurances_permanent_amount',
        type: 'currency',
      },
      {
        label: 'Monthly Disability Benefit',
        key: 'insurances_disability_amount',
        type: 'currency',
      },
      {
        label: 'Monthly Disability Replacement Ratio',
        key: 'ratio_disability_coverage',
        type: 'percent',
      },
      {
        label: 'Aggregate Disability Coverage',
        key: 'insurances_disability_contract_amount',
        type: 'currency',
      },
      {
        label: 'Daily Long-Term Care Benefit',
        key: 'insurances_ltc_amount',
        type: 'currency',
      },
      {
        label: 'Aggregate Long-Term Care Coverage',
        key: 'insurances_ltc_contract_amount',
        type: 'currency',
      },
    ],
  },
];

export const authFactorOptions = [
  {
    type: 'email',
    label: 'Email',
    displayValue: 'email',
    deleteLabel: 'email',
    description: 'Get verification codes sent to your email.',
    is_enabled: false,
    is_confirmed: false,
  },
  {
    type: 'phone',
    label: 'Text',
    displayValue: 'text',
    deleteLabel: 'phone number',
    description: 'Get verification codes sent to your mobile device.',
    is_enabled: false,
    is_confirmed: false,
  },
];

export const connectDisclaimer = `The information presented on this page is intended solely for viewing and informational purposes. It is not intended to provide personalized financial advice, recommendations, or guidance. Before making any financial decisions, it is essential that you consult with your financial advisor or professional.`;

/*
export const riskProfiles = [
  { label: '0: Unspecified', value: '0' },
  { label: '1: Conservative', value: '1' },
  { label: '2: Conservative Plus', value: '2' },
  { label: '3: Moderate', value: '3' },
  { label: '4: Moderate Plus', value: '4' },
  { label: '5: Aggressive', value: '5' },
  { label: '6: Speculative', value: '6' },
];

export const assetSubCategories = [
  { value: '', label: 'None' },
  { value: 'asset.checking', label: 'Checking' },
  { value: 'asset.checking.current', label: 'Checking Current Account' },
  { value: 'asset.checking.overdraft', label: 'Overdraft Account' },
  { value: 'asset.checking.cash', label: 'Cash Management' },
  { value: 'asset.savings', label: 'Savings' },
  { value: 'asset.savings.savings', label: 'Savings Account' },
  { value: 'asset.savings.moneymarket', label: 'Money Market' },
  { value: 'asset.savings.cod', label: 'Certificate of Deposit' },
  { value: 'asset.investment', label: 'Investment' },
  { value: 'asset.investment.fivetwentynine', label: '529' },
  { value: 'asset.investment.brokerage', label: 'Brokerage' },
  { value: 'asset.investment.mutual', label: 'Mutual Fund' },
  { value: 'asset.investment.fixed', label: 'Fixed Income' },
  { value: 'asset.investment.muni', label: 'Municipal Income' },
  { value: 'asset.investment.stock', label: 'Stock' },
  { value: 'asset.investment.equity', label: 'Hedge Fund / Private Equity' },
  { value: 'asset.investment.advisory', label: 'Investment Advisory' },
  { value: 'asset.other', label: 'Other' },
  { value: 'asset.other.other', label: 'Other Assets' },
  { value: 'asset.other.gic', label: 'GIC / Term Investment' },
  { value: 'asset.other.realestate', label: 'Investment Real Estate' },
  { value: 'asset.other.artwork', label: 'Artwork' },
  { value: 'asset.other.business', label: 'Business Interest' },
  { value: 'asset.insurance', label: 'Insurance' },
  { value: 'asset.insurance.annuity', label: 'Annuity' },
  { value: 'asset.insurance.cash', label: 'Cash Value' },
];

export const instrumentChoiceTypes = [
  { label: 'IRA', value: 'ira' },
  { label: 'Roth IRA', value: 'rothira' },
  { label: 'Simple IRA', value: 'simpleira' },
  { label: 'SEP IRA', value: 'sepira' },
  { label: '401(k)', value: '401k' },
  { label: '403(b)', value: '403b' },
  { label: '457', value: '457' },
  { label: '529', value: '529' },
  { label: 'Education Savings Account', value: 'education' },
  { label: 'Profit Sharing Plan', value: 'profitshare' },
  { label: 'Money Purchase', value: 'moneypurchase' },
  { label: 'Pension Plan', value: 'pension' },
  { label: 'RRSP', value: 'rrsp' },
  { label: 'RRIF', value: 'rrif' },
  { label: 'LIRA', value: 'lira' },
  { label: 'LRSP', value: 'lrsp' },
  { label: 'LIF', value: 'lif' },
  { label: 'LRIF', value: 'lrif' },
  { label: 'UGMA', value: 'ugma' },
  { label: 'UTMA', value: 'utma' },
];
*/

//Based off users date format preference these are the allowed input formats
//So you can enter any combo of single digit or double digit months, as well as 2 digit or 4 digit years
export const allowedDateFormats = {
  'YYYY/MM/DD': [
    'YY/M/D',
    'YY-M-D',
    'YY/MM/D',
    'YY-MM-D',
    'YY/M/DD',
    'YY-M-DD',
    'YYYY/M/D',
    'YYYY-M-D',
    'YYYY/MM/D',
    'YYYY-MM-D',
    'YYYY/M/DD',
    'YYYY-M-DD',
    'YYYY/MM/DD',
    'YYYY-MM-DD',
  ],
  'MM/DD/YYYY': [
    'M/D/YY',
    'M-D-YY',
    'MM/D/YY',
    'MM-D-YY',
    'M/DD/YY',
    'M-DD-YY',
    'MM/DD/YY',
    'MM-DD-YY',
    'M/D/YYYY',
    'M-D-YYYY',
    'MM/D/YYYY',
    'MM-D-YYYY',
    'M/DD/YYYY',
    'M-DD-YYYY',
    'MM/DD/YYYY',
    'MM-DD-YYYY',
  ],
  'DD/MM/YYYY': [
    'D/M/YY',
    'D-M-YY',
    'D/M/YYYY',
    'D-M-YYYY',
    'DD/M/YY',
    'DD-M-YY',
    'D/MM/YY',
    'D-MM-YY',
    'DD/MM/YY',
    'DD-MM-YY',
    'DD/M/YYYY',
    'DD-M-YYYY',
    'D/MM/YYYY',
    'D-MM-YYYY',
    'DD/MM/YYYY',
    'DD-MM-YYYY',
  ],
};

export const legendItems = [
  {
    label: 'Cash Flow',
    background: assetMapColors.cashFlow,
    border: `thin solid ${assetMapColors.cashFlow}`,
    borderRadius: '3px',
    context: ['amr', 'beneficiaries'],
  },
  {
    label: 'Real Estate',
    background: assetMapColors.realEstate,
    border: `thin solid ${assetMapColors.realEstate}`,
    borderRadius: '1px',
    context: ['amr', 'beneficiaries'],
  },
  {
    label: 'Retirement Asset',
    background: assetMapColors.retirement,
    border: `thin solid ${assetMapColors.retirement}`,
    borderRadius: '1px',
    context: ['amr', 'beneficiaries'],
  },
  {
    label: 'Non-Retirement Asset',
    background: assetMapColors.nonRetirement,
    border: `thin solid ${assetMapColors.nonRetirement}`,
    borderRadius: '1px',
    context: ['amr', 'beneficiaries'],
  },
  {
    label: 'Liability',
    background: assetMapColors.liability,
    border: `thin solid ${assetMapColors.liability}`,
    borderRadius: '1px',
    context: ['amr', 'beneficiaries'],
  },
  {
    label: 'Insurance',
    background: assetMapColors.insurance,
    border: `thin solid ${assetMapColors.insurance}`,
    borderRadius: '1px',
    context: ['amr', 'beneficiaries'],
  },
  {
    label: 'Owned By Another',
    background: assetMapColors.otherOwner,
    border: `thin solid ${assetMapColors.stencil}`,
    borderRadius: '1px',
    context: ['amr'],
  },
  {
    label: 'Deferred Annuity',
    background: 'none',
    border: `thin dashed ${colors.paleGrey}`,
    borderRadius: '1px',
    context: ['amr'],
  },
  {
    label: 'Expected',
    background: colors.lightGrey,
    border: `thin solid ${assetMapColors.expected}`,
    borderRadius: '1px',
    context: ['amr'],
  },
  {
    label: 'Stencil',
    background: colors.lightGrey,
    border: `thin solid ${assetMapColors.stencil}`,
    borderRadius: '1px',
    context: ['amr'],
  },
];
